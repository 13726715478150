import Dashboard from "../../Company/Dashboard"
import DashboardEmp from "../../Employee/Dashboard/Index"

export default function InitialPage() {
    const userData = JSON.parse(localStorage.getItem('user_ow'))

    return (

         userData && userData.role === 'ADM' || userData && userData.role === 'AUX' ? <Dashboard /> : <DashboardEmp /> 

    );
}