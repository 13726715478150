import { useState } from "react";
import useService from "@hooks/useService";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import  { toast } from "react-toastify";
function FormWaitList() {
	const db = useService("wait.list");

	const [validated, setValidated] = useState(false);
	const [success, setSuccess] = useState(false);
	const [state, setState] = useState({
		name: "",
		email: "",
		howDidYouKnow: "",
	});

	function handleChange(evt) {
		const value = evt.target.value;
		setState({
			...state,
			[evt.target.name]: value,
		});
	}

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}

		event.preventDefault();
		event.stopPropagation();

		try {
			setValidated(true);		
			const create = await db.create(state);
	
			if (!create.error) {
				setSuccess(true)
			} else {
				toast.error('Erros foram encontrados no formulário')
			}
		} catch(err) {
			console.log(err);
		}
	};

	return (
		<main role="main" className="container bg-wait">
			<img src='/onework-f.png' width={150} />
			<h1 className="mt-5">Seja um dos primeiros a conhecer a <br /> <b>nova gestão </b>para seu time de People!</h1>
			<img src='line.png' width={300} />
			<p className="mt-4">Entre em nossa lista de espera.</p>
			<Row className="justify-content-md-center">
				<Col md={6} xs={12} lg={6} >
					<Card className="form">
						<Card.Body>
							{!success ? <Form noValidate validated={validated} onSubmit={handleSubmit}>
								<Row className="mb-3">
									<Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
										<Form.Label>Seu nome</Form.Label>
										<Form.Control required type="text" placeholder="Nome completo" name="name" onChange={handleChange} className='input' />
										<Form.Control.Feedback>OK!</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-3">
										<Form.Label>Seu melhor e-mail</Form.Label>
										<Form.Control required type="text" placeholder="seumelhoremail@provedor.com.br" name="email" onChange={handleChange} className='input' />
										<Form.Control.Feedback>OK!</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationCustom03" className="mb-3">
										<Form.Label>Como conheceu a OneWork?</Form.Label>
										<Form.Control type="text" placeholder="Relate brevemente" name="howDidYouKnow" onChange={handleChange} className='input' />
									</Form.Group>
								</Row>
								<Button type="submit">Entrar para lista de espera</Button>
							</Form> :
								<div className="sucesso">
									<img src="/hero-shape-2.png" />
									<h2>Seu contato foi adicionado a lista de espera!</h2>
									<p>Em breve te atualizaremos sobre as novidades.</p>
								</div>}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</main>
	);
}

export default FormWaitList;
