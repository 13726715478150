import BaseService from "./Base.Service";

class ClaimsService extends BaseService {
	constructor() {
		const model = {
			companyId: { type: String, required: true },
			category: { type: String, required: true },
			area: { type: String, required: true },
			from: { type: String, required: true },
			text: { type: String, required: true },
			answer: { type: String, required: true },
			identify: { type: Boolean, required: true },
		};

		super("claims", model);
	}

    async upvote(claimId) {
        try {
            const { data } = await this.conn.axios.post(`/claims/upvote`, { claimId });
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }
}

export default ClaimsService;
