
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import Modal from 'react-bootstrap/Modal';
import Loading from '@components/Loading'
import { formatDate } from '../../../../helpers'
export default function Declarations() {
    const declarations = useService('declarations');
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        employee: [],
        search: '',
    })
    const [indexView, setIndexView] = useState(null)
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClose = () => setShow(false);

    const handleShow = (index) => {
        setIndexView(index)
        setShow(true)
    }
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {
        fetchDeclarations();
    }, []);

    async function fetchDeclarations() {
        try {
            const query = `select=id|type|createdAt|annonymous|text|votes`
            const result = await declarations.list(query);
            setPaginationData(result.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }

    function NothingFound() {
        return (
            <div>
                 <div className="notFound">
                    <h4>Não encontramos nenhum dado até o momento</h4>
                 
                    <br />
                    <img alt="No Data" src="/no-dec.jpeg" className="blend-img" width={400} />
                </div>
               
            
            </div>
        );
    }

    function ListAndPaginate() {
        return (
            <Table hover className="rounded-table">
                <thead>
                    <tr>
                        {/* <th>Área/Categoria</th> */}
                        <th>Tipo</th>
                        <th style={{ width: '400px' }}>Descrição</th>
                        <th>Criação</th>
                        <th>Votos</th>
                        <th>Criado por</th>
                        <th style={{ width: '150px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, index) => {
                        return (
                            <tr>
                                {/* <td>{item.area}/{item.category}</td> */}
                                <td>{item.type}</td>
                                <td>{item.text}</td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td><svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                </svg> {item.votes}
                                </td>
                                <td>
                                    Enviado por <strong>{item.identify ? item.createdBy ? item.createdBy.name : 'Anônimo' : 'Anônimo'}</strong>
                                </td>
                                <td>
                                    <Col className="action-buttons">
                                        <OverlayTrigger placement="top" overlay={tooltip("Visualizar")}>
                                            <a href="#" onClick={() => handleShow(index)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>

            </Table>
        );
    }

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={12}>
                    <h2>Declarações</h2>
                    <p>Fique por dentro de tudo o que seus colaboradores tem a dizer.</p>
                </Col>
                {paginationData && <Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='p-2 text-left'>

                            {indexView != null || undefined ? <>
                                <h5>{paginationData.list[indexView].type}</h5>
                                <p>{paginationData.list[indexView].text}</p>
                            </>
                                : null}

                        </div>

                    </Modal.Body>
                </Modal>}
                
            </Row>

            <Row>
                <Col md={4}>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <ButtonOw type={'button'} variant={'secondary'} >Filtrar</ButtonOw>
                </Col>

            </Row >

            <Row className="mt-4">
                {loading ? loadingPage() : paginationData.totalRegisters === 0 ? NothingFound() : ListAndPaginate()}
            </Row>
        </div >
    );
}
