import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";

import React from "react";

const Auth = ({ allowedRoles }) => {
  const auth = JSON.parse(localStorage.getItem('user_ow'));
  const location = useLocation();

  return allowedRoles.find((role) => auth.role.includes(role)) ? (
    <Outlet />
  ) : auth?.name ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/sign-up" state={{ from: location }} replace />
  );
};

export default Auth;