import {
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Navbar,
  Container,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import useService from "../../hooks/useService";
import useComponentVisible from "../../hooks/useComponentVisible";
import MenuCompany from "./MenuCompany";
import MenuEmployee from "./MenuEmployee";
import OverlayOw from "../../components/OverlayOW";
export default function Layout() {
  const authService = useService("auth");
  const [user, setUser] = useState({});
  const [ready, setReady] = useState(false);
  const [logo, setLogo] = useState("/ow.png");
  const [open, setOpen] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [styleVars, setStyleVars] = useState({});
  const [remainDays, setRemainDays] = useState(null);
  var today = new Date().getTime();

  useEffect(() => {
    checkUserLogged();
    getSubscription();
    setInterval(async () => {
      await authService.me("complete=1");
    }, 1000 * 60 * 2);
  }, []);

  async function getCompanyData() {}
  async function getSubscription() {}

  async function checkUserLogged() {
    try {
      const { data } = await authService.me();

      if (!data) {
        window.location.href = "/sign-in";
      } else {
        setUser(data);
        localStorage.setItem("user_ow", JSON.stringify(data));

        if (data.companyData) {
          if (data.companyData.logos && data.companyData.logos.smallLogo) {
            const logoAddress = `https://api.onework.com.br/public/${data.companyData.logos.smallLogo}`;
            setLogo(logoAddress);
          }

          let colors = {};

          if (data.companyData.colors && data.companyData.colors.primary) {
            colors["--primary-color"] = data.companyData.colors.primary;
          }
          if (data.companyData.colors && data.companyData.colors.secondary) {
            colors["--secondary-color"] = data.companyData.colors.secondary;
          }
          if (data.companyData.colors && data.companyData.colors.textColor) {
            colors["--text-color"] = data.companyData.colors.textColor;
          }
          setStyleVars(colors);
        }

        setReady(true);
      }
    } catch (err) {
      console.log(err);
      console.log(err.status);
    }
  }

  async function logout() {
    try {
      localStorage.clear();
      const logout = await authService.logoutUser();
      window.location.href = "/sign-in";
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {!ready && <OverlayOw />}

      {ready && (
        <div className="container-fluid" style={styleVars}>
          <Row>
            {" "}
            <Navbar className="bg-body-terciary">
              <Container fluid>
                <Navbar.Toggle style={{ display: "none" }} />
                <Navbar.Collapse className="justify-content-end mx-5">
                  <Nav ref={ref}>
                    {remainDays && (
                      <>
                        <span className="trial">
                          Você está em período de testes
                        </span>
                      </>
                    )}
                    <button
                      className="menu-user"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      {" "}
                      <svg
                        width={25}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>{" "}
                    </button>
                    <div
                      className={
                        isComponentVisible
                          ? `menu-user-open show`
                          : `menu-user-open `
                      }
                    >
                      <div className="info-user">
                        <h5>{user && user.name}</h5>
                      </div>
                      <ul>
                        <li>
                          <Link to="/profile">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                              />
                            </svg>
                            Perfil
                          </Link>
                        </li>
                        {user && user.role === "EMP" ? (
                          <>
                            <li>
                              <Link to="/e/bank">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                                  />
                                </svg>
                                Financeiro
                              </Link>
                            </li>
                          </>
                        ) : (
                          <>
                            {" "}
                            <li>
                              <Link to="/config">
                                <svg
                                  width={25}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                                Configurações
                              </Link>
                            </li>
                          </>
                        )}
                        <hr />
                        <li>
                          <Link
                            onClick={() => logout()}
                            className="text-danger"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                              />
                            </svg>
                            Sair
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <NavDropdown
                                        drop="down"

                                        title={<div className="menu-user"> {user && user.name}  </div>}
                                        menuVariant="light"
                                    >
                                            

                                        <NavDropdown.Item href="/profile"><svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>

                                            Perfil</NavDropdown.Item>



                                    </NavDropdown> */}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div className="menu1">
              <a href="/">
                <img alt="" src={logo} />
              </a>

              {user && user.role === "EMP" ? <MenuEmployee /> : <MenuCompany />}
            </div>
            <main className="main-content">
              <Col md="12" lg="12" xl="12">
                <div className="main">
                  <Outlet />
                  <div className="logo-footer-app">
                    powered by
                    
                    <a href="https://contabilidade.com/" target="_blank">
                      <img src="/og-image.png" />
                    </a>
                  </div>
                </div>
              </Col>
            </main>
          </Row>
        </div>
      )}
    </>
  );
}
