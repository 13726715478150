import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "../../../../components/ButtonOw";
import { toast } from "react-toastify";
export default function NewAlert() {
	const alertsService = useService('alerts');

	const [loadingCreate, setLoadingCreate] = useState(false)

	//Select para compartilhar com usuário utilizar em atualização
	// const [employe, setEmploye] = useState(null)
	// const selectShareEmployee = (e) => {
	// 	if (e) {
	// 		const ev = []
	// 		e.map((item) => {
	// 			ev.push(item.id)
	// 		})
	// 		setEmploye(ev);
	// 	}
	// }

	async function create(event) {
		setLoadingCreate(true)
		event.preventDefault();
		event.stopPropagation();

		const formData = new FormData(event.currentTarget);
		
		try {
			await alertsService.create(formData)
			toast.success('Comunicado criado com sucesso')
			setLoadingCreate(false)
			// const timer = setTimeout(() => {

			// 	window.location.href = '/c/alerts'
			// }, 1500);
			// return () => clearTimeout(timer);
		} catch (err) {
			toast.error(err)
		}

	}
	return (
		<div className="dashboard mt-5">
			<Row>
				<Col md={10}>
					<a href="/c/alerts">Voltar</a>
					<h2 className="mt-3">Novo Comunicado</h2>
					<p>Comunique-se com seus colaboradores de maneira simples e unificada, envie avisos a qualquer momento.</p>
				</Col>


			</Row>

			<Row>
				<Col md={6} xs={12} sm={12}>
					<Form onSubmit={create}>
						<Form.Group controlId="formFile" className="mb-4 mt-4 ">
							<Form.Label className="mx-2">Selecione uma imagem</Form.Label>
							<Form.Control type="file" name='file' />
						</Form.Group>
						<Form.Group controlId="validationCustom01" className="mb-5">
							<Form.Label className="mx-2">Comunicado</Form.Label>
							<Form.Control type="text" rows={3} as="textarea"  placeholder="ex: Férias coletivas" name="text" className="input"  />
							{/* <Form.Control type="text" placeholder="ex: Férias coletivas" name="text" className="input" /> */}
						</Form.Group>
						{/* <Form.Label className="mx-2">Selecione o(s) colaborador(es)</Form.Label>
						<SelectEmployee className={'mt-4'} name="employee1" onChange={selectShareEmployee} /> */}

						<ButtonOw type={'submit'} variant='primary' loading={loadingCreate}>Criar comunicado</ButtonOw>
					</Form>

				</Col>
				<Col md={4} className='mx-5'>
					<img alt="Comunicados" src="/no-com.jpeg" className="blend-img" width={400} />

				</Col>
			</Row>

		</div>
	);
}
