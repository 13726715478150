
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import { toast } from "react-toastify";
import Loading from '@components/Loading'
import PaginationOw from '@components/PaginationOw'
import { formatDate } from "../../../../helpers";

export default function QuizEmployee() {
	const quizService = useService('quiz');
	const user = JSON.parse(localStorage.getItem('user_ow'))
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [loadingView, setLoadingView] = useState(false)
	const [paginationData, setPaginationData] = useState({
		totalRegisters: 0
	});
	const [form, setForm] = useState({
		search: '',
	})
	const [viewSheet, setViewSheet] = useState(false)
	const tooltip = (name) => {
		return (
			<Tooltip id="tooltip">
				<strong>{name}</strong>
			</Tooltip>
		);
	};
	useEffect(() => {
		if (form.search.length > 0) {
			search()
		} else {
			GetDocuments();
		}

	}, [page]);

	async function GetDocuments() {
		setLoading(true)
		try {
			const result = await quizService.list('page=' + page+ `&companyId=${user.companyId}`);
			setPaginationData(result.data)
			setLoading(false)
		} catch (err) {
			setLoading(false)
			toast.error(err)
		}

	}
	async function search() {

		if (form.search.length > 0) {
			setLoading(true)
			try {
				const result = await quizService.list('page=' + page + '&title=' + form.search);
				setPaginationData(result.data)
				setLoading(false)
			} catch (err) {
				setLoading(false)
				toast.error(err)
			}
		} else {
			GetDocuments()
		}

	}

	function NothingFound() {
		return (
			<div>
				<NotFound text={'Não encontramos nenhum Questionário.'} />
			</div>
		);
	}

	function ListAndPaginate() {
		return (
			<Table hover className="rounded-table">
				<thead>
					<tr>
						<th className="text-start">Nome</th>
						<th>Número de questões</th>
						<th>Status</th>
						<th>Data de criação</th>
						<th style={{ width: '250px' }}></th>
					</tr>
				</thead>
				<tbody>
					{paginationData.list.map((item, index) => {

						return (

							item.status === 'ATIVO' &&
							<tr key={index}>
								<td className="text-start">{item.title}</td>
								<td>{item.totalAnswers}/{item.totalQuestions}</td>
								<td>{item.totalAnswers >= item.totalQuestions ? 'Respondido' : 'Pendente'}</td>
								<td>{formatDate(item.createdAt)}</td>
								<td><ButtonOw disabled={item.totalAnswers >= item.totalQuestions} variant='white' onClick={() => window.open('/quiz/view/' + item.id)}> Responder
								</ButtonOw>
								</td>
							</tr>

						)
					})}

				</tbody>
			</Table>
		);
	}


	function handleChange(e) {
		setForm((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	}

	return (
		<div className="dashboard mt-5">
			{loading ?
				<div className="d-flex justify-content-center align-itens-center">
					<Loading variant='secondary' />
				</div>
				: <>
					<Row className="">
						<Col md={11}>
							<h2>Questionários</h2>
							<p>Verifique os questionários disponíveis.</p>
						</Col>

					</Row>

					<Row className="">
						<Col md={5}>
							<Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
								<Form.Control type="text" placeholder="Pesquisar" name="search" className="input" defaultValue={form.search} onChange={handleChange} />
							</Form.Group>
						</Col>
						<Col md={6}>
							<ButtonOw type={'button'} variant={'secondary'} onClick={search}>Filtrar</ButtonOw>
						</Col>

					</Row >

					<Row className="mt-4 ">
						<Col md={12} className=''>
							{paginationData.totalRegisters === 0 ? NothingFound() : ListAndPaginate()}
						</Col>

					</Row>
					<Row>
						<Col md={12} className='d-flex justify-content-center align-itens-center'>
							<PaginationOw totalPages={paginationData.totalPages} currentPage={page} onClick={setPage} />
						</Col>
					</Row>
				</>}
		</div >
	);
}
