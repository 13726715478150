import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import readXlsxFile from 'read-excel-file'
import { convertExcelToJson } from "../../helpers"
import convertToJson from "read-excel-file/map"
import { read, writeFileXLSX, utils } from "xlsx";
import { toast } from "react-toastify"
import useService from "../../hooks/useService"
import ButtonOw from "../ButtonOw"
export default function DragNDrop({handleClose}) {
  const authService = useService("auth");
  const { companyData } = JSON.parse(localStorage.getItem('user_ow'))
  const [data, setData] = useState(null)
  
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        json.map((item) => {
          item.role = 'EMP'
          item.company = companyData.id
        })
        setData(json)
      };
      reader.readAsArrayBuffer(file);
    })
  }, [])

  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop })

  const upload = async () => {
    try {
      setLoading(true)
      const res = await authService.batchAddUser(data)
      setLoading(false)
      handleClose()
      toast.success('O processo de importação foi realizado com sucesso!')
      
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <div className="container-drop">
      <div className="zone">
        {data ? (
          <div className="selected">
            <p><b>Arquivo recebido</b>, podemos prosseguir para o cadastro em massa baseado nesse arquivo?</p>
            <small>Essa ação pode demorar alguns minutos para ser concluída</small>
            <div className="actions">

              <ButtonOw
                onClick={upload}
                className="upload-btn"
                loading={loading}
              >
                Prosseguir
              </ButtonOw>

              <button
                onClick={() => setData(null)}
                className="cancel-btn"
              >
                Cancelar
              </button>
            </div>
          </div>
        ) : (
          <div className="drop-zone" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="drop-files">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height="50"
                  width="50"
                  fill="currentColor"
                >
                  <path d="M1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811L7 21C3.64378 20.7257 1 17.9216 1 14.5ZM16.8483 18.9868C19.1817 18.8093 21 16.8561 21 14.5C21 12.927 20.1884 11.4962 18.8771 10.6781L18.0714 10.1754L17.9517 9.23338C17.5735 6.25803 15.0288 4 12 4C8.97116 4 6.42647 6.25803 6.0483 9.23338L5.92856 10.1754L5.12288 10.6781C3.81156 11.4962 3 12.927 3 14.5C3 16.8561 4.81833 18.8093 7.1517 18.9868L7.325 19H16.675L16.8483 18.9868ZM13 13V17H11V13H8L12 8L16 13H13Z"></path>
                </svg>
              </div>
            ) : (
              <div className="drag-files">
                Drop your files here or click to browse
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}