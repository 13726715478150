
import { Col, Row, Tooltip, OverlayTrigger, Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import Modal from 'react-bootstrap/Modal';
import Loading from '@components/Loading'
import { money, maskDocument } from "../../../../helpers";

export default function Profile() {
    const { id } = JSON.parse(localStorage.getItem('user_ow'))
    const [user, setUser] = useState({})
    const userService = useService('users');
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        getMe();
    }, []);

    async function getMe() {
        setLoading(true)
        const res = await userService.get(id);
        console.log(res)
        if (!res.error) {
            setUser(res.data)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }


    return (
        <div className="dashboard mt-5 text-center">
            {!loading ? <>

                <Row className="mt-4">
                    <Col>
                        {user.avatar ? <Image src={user.avatar} circle style={{ maxWidth: 150 }} /> : <svg width={145} className='mx-2' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>}

                        <h5 className="mt-3">{user.name}</h5>
                        <p>{user.email}</p>
                        {user.role != 'ADM' && <p>Tipo de contrato: {user.typeContract}</p>}
                        <p>Salário atual: {money(user.currentSalary)}</p>
                        <p>Cargo: {user.job}</p>
                        <p>Documento: {user.document && maskDocument(user.document)}</p>

                    </Col>
                </Row>
            </> : loadingPage()}
        </div >
    );
}
