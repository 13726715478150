import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "../../../../components/ButtonOw";
import { toast } from "react-toastify";
export default function UploadDocument() {
	const documentsService = useService('documents');
	const [employe, setEmploye] = useState(null)
	const [loadingCreate, setLoadingCreate] = useState(false)
	const selectShareEmployee = (e) => {
		if (e) {
			const ev = []
			e.map((item) => {
				ev.push(item.id)
			})
			setEmploye(ev);
		}
	}

	async function create(event) {
		setLoadingCreate(true)
		event.preventDefault();
		event.stopPropagation();

		const formData = new FormData(event.currentTarget);
		formData.append('sharedWith', employe)
		try {
			await documentsService.uploadDocument(formData)
			toast.success('Documento criado com sucesso')
			setLoadingCreate(false)
			const timer = setTimeout(() => {
				
				window.location.href = '/c/documents'
			}, 1500);
			return () => clearTimeout(timer);
		} catch (err) {
			toast.error(err)
		}

	}
	return (
		<div className="dashboard mt-5">
			<Row>
				<Col md={10}>
					<a href="/c/documents">Voltar</a>
					<h2 className="mt-3">Novo Documento</h2>
					<p>Faça o upload de um novo documento, nomeie e associe a um colaborador para que ele também possa visualizar.</p>
				</Col>


			</Row>

			<Row>
				<Col md={6} xs={12} sm={12}>
					<Form onSubmit={create}>
						<Form.Group controlId="validationCustom01" className="mt-4 mb-4">
							<Form.Label className="mx-2">Nome do documento</Form.Label>
							<Form.Control type="text" placeholder="ex: Contrato de Serviço" name="filename" className="input" />
						</Form.Group>
						<Form.Label className="mx-2">Selecione o(s) colaborador(es)</Form.Label>
						<SelectEmployee className={'mt-4'} name="employee1" onChange={selectShareEmployee} />
						<Form.Group controlId="formFile" className="mb-5 mt-4 ">
							<Form.Label className="mx-2">Selecione seu arquivo</Form.Label>
							<Form.Control type="file" name='file' />
						</Form.Group>
						<ButtonOw type={'submit'} variant='primary' loading={loadingCreate}>Enviar novo documento</ButtonOw>
					</Form>

				</Col>
				<Col md={4} className='mx-5'>
					<img alt="documents" src="/documents.png" className="" width={400} />
				</Col>
			</Row>

			
		</div>
	);
}
