
import Pagination from 'react-bootstrap/Pagination';
export default function PaginationOw({ totalPages, currentPage, onClick }) {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => onClick(number)}>
                {number}
            </Pagination.Item>
        );
    }
    return (
        <Pagination>{items}</Pagination>
    );
}