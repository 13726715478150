import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Card from '@components/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import useService from '@hooks/useService';
import ButtonOw from '@components/ButtonOw';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import SelectEmployee from '@components/SelectEmployee';
import NotFound from '@components/NotFound';
import { toast } from 'react-toastify';
import Loading from '@components/Loading';
import PaginationOw from '@components/PaginationOw';
import { formatDate } from '../../../../helpers';
export default function Quiz() {
    const quizService = useService('quiz');
    const [loadingView, setLoadingView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0,
    });
    const [form, setForm] = useState({
        search: '',
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {
        if (form.search.length > 0) {
            search();
        } else {
            getQuiz();
        }
    }, [page]);

    async function getQuiz() {
        setLoading(true);
        try {
            const result = await quizService.list('page=' + page);
            setPaginationData(result.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err);
        }
    }
    async function search() {
        if (form.search.length > 0 || form.employee.length > 0) {
            setLoading(true);

            const stringData = form.employee.reduce((result, item) => {
                return `${result}${item}|`;
            }, '');

            const sharedWith = stringData.slice(0, -1);
            try {
                const result = await quizService.list('page=' + page + '&filename=' + form.search + '&sharedWith=' + sharedWith);
                setPaginationData(result.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setPaginationData({
                    totalRegisters: 0,
                });
                toast.error(err);
            }
        } else {
            getQuiz();
        }
    }

    async function destroyQuiz(id) {
        setShow(false);
        try {
            const res = await quizService.destroy(id);
            const timer = setTimeout(() => {
                window.location.href = '/c/quiz';
            }, 1000);
        } catch (err) {
            toast.error(err);
        }
    }
    function showModalDelete(id) {
        setDeleteId(id);
        handleShow();
    }

    function NothingFound() {
        return (
            <div>
                <div className="notFound">
                    <h4>Não encontramos nenhum questionário, deseja criar um?</h4>
                    <ButtonOw
                        type={'button'}
                        variant={'secondary'}
                        onClick={() => (window.location.href = '/c/quiz/new')}>
                        Criar Questionário
                    </ButtonOw>
                    <br />
                    <img
                        alt="No Data"
                        src="/no-quiz.jpeg"
                        className="blend-img"
                        width={400}
                    />
                </div>
            </div>
        );
    }

    function ListAndPaginate() {
        return (
            <Table
                hover
                className="rounded-table">
                <thead>
                    <tr>
                        <th>Título</th>
                        <th>Status</th>
                        <th>Questões</th>
                        <th style={{ width: '250px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.status}</td>
                                <td>{item.totalQuestions}</td>
                                <td>
                                    <Col className="action-buttons">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={tooltip('Editar')}>
                                            <a href={'/c/quiz/' + item.id}>
                                                <svg
                                                    width={25}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                                    />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={tooltip('Visualizar')}>
                                            <a
                                                href={'/quiz/view/' + item.id}
                                                target="_blank">
                                                <svg
                                                    width={25}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={tooltip('Deletar')}>
                                            <a
                                                href="#"
                                                className="delete"
                                                onClick={() => showModalDelete(item.id)}>
                                                <svg
                                                    width={25}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    return (
        <div className="dashboard mt-5">
            <>
                <Row>
                    <Col md={10}>
                        <h2>Questionários</h2>
                        <p>Visualize a organize melhor os questionários para enviar aos seus colaboradores.</p>
                    </Col>
                    <Col
                        md={2}
                        className="">
                        <ButtonOw
                            variant="primary"
                            onClick={() => (window.location.href = '/c/quiz/new')}
                            type={'button'}>
                            <svg
                                width={18}
                                style={{ marginRight: 10 }}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            Novo
                        </ButtonOw>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Pesquisar"
                                name="search"
                                className="input"
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <ButtonOw
                            type={'button'}
                            variant={'secondary'}
                            onClick={search}>
                            Filtrar
                        </ButtonOw>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {loadingView || loading ? (
                        <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                            <Loading variant="secondary" />
                        </div>
                    ) : paginationData && paginationData.totalRegisters === 0 ? (
                        NothingFound()
                    ) : (
                        ListAndPaginate()
                    )}
                </Row>
                <Row>
                    <Col
                        md={12}
                        className="d-flex justify-content-center align-itens-center">
                        <PaginationOw
                            totalPages={paginationData.totalPages ? paginationData.totalPages : 0}
                            currentPage={page}
                            onClick={setPage}
                        />
                    </Col>
                </Row>
            </>
            <Modal
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center mb-4">
                    <img
                        alt="delete"
                        src="/delete1.svg"
                        width={300}
                    />
                    <h5 className="mb-4 mt-3">
                        Você tem certeza que <br />
                        deseja deletar esse questionário?
                    </h5>
                    <ButtonOw
                        variant="danger"
                        onClick={() => destroyQuiz(deleteId)}>
                        Confirmar
                    </ButtonOw>
                </Modal.Body>
            </Modal>
        </div>
    );
}
