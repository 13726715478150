import BaseService from "./Base.Service";

class ToolService extends BaseService {
    constructor() {
		super("companies");
	}

    async searchForCep(cep) {
        try {
            const { data } = await this.conn.axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            return data;
        } catch (err) {
            return {
                error : true,
                msg : err.toString()
            };
        }
    }
}

export default ToolService;
