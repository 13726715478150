import BaseService from "./Base.Service";

class UserService extends BaseService {
	constructor() {
        const model = {
			name: { type: String, validations: ['isNotEmpty', 'isCompleteName'] },
			email: { type: String, validations: ['isNotEmpty', 'isValidEmail'] },
			howDidYouKnow: { type: String }
		};

        super("wait.list", model)
    }
}

export default UserService;