const data = [
    {
        name: 'Bem-vindo',
        contentType: 'text',
        content: 'Seja muito bem vindo a empresa X, estamos muito contentes em te receber aqui. Aqui estão os próxmos passos para sua jornada dentro da X'
    },
    {
        name: 'Nossos ideais',
        contentType: 'image',
        content: 'https://www.decisivacontabilidade.com.br/wp-content/uploads/2017/08/gest%C3%A3o-de-pessoas.png'
    },
    {
        name:'O que você vai fazer?',
        contentType: 'video',
        content: 'https://vimeo.com/347119375',
    },
    // Precisa adaptar ainda a utilização de PDFs
    // {
    //     name:'Veja nosso PDF!',
    //     contentType: 'pdf',
    //     content: 'https://www.pdf995.com/samples/pdf.pdf'
    // }
]
export default data