import { Badge } from "react-bootstrap";
import { useState } from "react";
import ButtonOw from "../ButtonOw";
export default function Clock({ actions, children }) {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

    const [h, setH] = useState(new Date().getHours().toString().padStart(2,'0'));
    const [m, setM] = useState(new Date().getMinutes().toString().padStart(2,'0'));
    const [s, setS] = useState(new Date().getSeconds().toString().padStart(2,'0'));

    function checkTime() {
        const h = new Date().getHours().toString().padStart(2,'0');
        const m = new Date().getMinutes().toString().padStart(2,'0');
        const s = new Date().getSeconds().toString().padStart(2,'0');

        setH(h);
        setM(m);
        setS(s);
    }

    setInterval(() => checkTime(), 1000);

    return (
        <Badge bg='success-ow' style={{ width: '100%' }} className='mb-2 p-3'>
            {weekDays[new Date().getDay()]}, {new Date().getDate()} de {months[new Date().getMonth()]} de {new Date().getFullYear()} {h}:{m}:{s}
        </Badge>
    );
}