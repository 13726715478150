import { Col, Row, OverlayTrigger, Tooltip, InputGroup, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import ButtonOw from "@components/ButtonOw";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import useService from "@hooks/useService";
import { toast } from "react-toastify";

export default function Login() {
    const authService = useService('auth');
    const [isView, setIsView] = useState(false);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        pass: "",
        email: "",
    });

    useEffect(() => {
        checkUserLogged()
        
    }, []);

    async function checkUserLogged() {
        const { data } = await authService.me();
        if (data) {
            window.location.href = '/';
        }
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        event.stopPropagation();

        try {
            const response = await authService.loginUser(state.email, state.pass)
            if (!response) {
                toast.error('Tivemos um erro ao realizar seu login, verifique os dados inseridos.')
            } else {
                localStorage.setItem('idToken_ow', response.access_token)
                const { data } = await authService.me();
                localStorage.setItem('user_ow', JSON.stringify(data))
                if (localStorage.getItem('last_page')) {
                    window.location.href = localStorage.getItem('last_page')
                } else {
                    window.localStorage.href = '/'
                }

            }
        } catch (err) {
            toast.error('Tivemos um erro ao realizar seu login, verifique os dados inseridos.')
        }
        setLoading(false)
    }
  
    return (

        <Row className="login">
            <Col md={12} lg={12} xl={12}>
                {/* <img alt="robo" src="/hero-shape-2.png" className="robo" />
                    <img alt="shape" src="/hero-shape-1.png" className="shape" /> */}

                <div className="formLogin">

                    <h2>Sign in</h2>
                    <p>We suggest using the email address you use at work.</p>
                    {/* <div className="sso">
                            <button type='button' className="btn btn-outline disabled" disabled > <svg className="mr-10" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.5642 11.7235C21.5642 10.8961 21.4957 10.2924 21.3474 9.66626H11.707V13.4005H17.3657C17.2517 14.3285 16.6356 15.7261 15.2665 16.6652L15.2473 16.7902L18.2954 19.1044L18.5066 19.125C20.4461 17.3696 21.5642 14.7869 21.5642 11.7235" fill="#4285F4"></path>
                                <path d="M11.7039 21.5625C14.4761 21.5625 16.8035 20.668 18.5034 19.1251L15.2633 16.6653C14.3963 17.2579 13.2326 17.6716 11.7039 17.6716C8.98861 17.6716 6.68407 15.9163 5.86257 13.4901L5.74215 13.5001L2.5727 15.9039L2.53125 16.0168C4.21972 19.3039 7.68797 21.5625 11.7039 21.5625Z" fill="#34A853"></path>
                                <path d="M5.86404 13.4901C5.64728 12.864 5.52183 12.1931 5.52183 11.5C5.52183 10.8067 5.64728 10.1359 5.85263 9.50984L5.84689 9.37649L2.63771 6.93405L2.53271 6.983C1.83681 8.34704 1.4375 9.87881 1.4375 11.5C1.4375 13.1212 1.83681 14.6528 2.53271 16.0169L5.86404 13.4901" fill="#FBBC05"></path>
                                <path d="M11.7039 5.32831C13.632 5.32831 14.9325 6.14448 15.6741 6.82654L18.5719 4.05375C16.7922 2.43257 14.4762 1.4375 11.7039 1.4375C7.688 1.4375 4.21973 3.69595 2.53125 6.98301L5.85118 9.50985C6.6841 7.08367 8.98865 5.32831 11.7039 5.32831" fill="#EB4335"></path>
                            </svg> Continue with Google</button>
                          
                            <p>Or Sign In with email</p>
                        </div> */}
                    <Form onSubmit={handleSubmit}>
                        {/* <div className="badge-info">
                                <Badge bg={'degrade'}>Que bom te ver novamente</Badge>
                            </div> */}

                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="text" placeholder="john@onework.com" name="email" onChange={handleChange} className='input' />

                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type={isView ? 'text' : 'password'} placeholder="******" name="pass" onChange={handleChange} className='input' />
                                <div className="icon" onClick={() => setIsView(!isView)}>
                                    {isView ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    }
                                </div>

                            </Form.Group>

                            <Row>
                                {/* <Col md={6} lg={6} xl={6}>
                                        <a href="/forgot-password" className="mt-3 mb-3">Forgot your password?</a>
                                    </Col> */}
                                <Col md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                    <a href="/sign-up" className="mt-3 mb-3">Don't have a account?</a>
                                </Col>
                            </Row>

                        </Row>
                        <ButtonOw type="submit" loading={loading} variant={'primary'}>Login</ButtonOw>
                    </Form>
                </div>
                {/* <img alt="" src="/login-shape-1.png" className="shape2" /> */}
            </Col>
            <div className="logo-footer">
                powered by<br />
                <a href="https://contabilidade.com/" target="_blank">
                    <img src="/og-image.png" />
                </a>
            </div>
        </Row>

    );
}