import UserService from "../services/User.Service";
import DocumentService from "../services/Document.Service";
import OnboardingService from "../services/Onboarding.Service";
import QuizService from "../services/Quiz.Service";
import ClaimsService from "../services/Claims.Service";
import DeclarationsService from "../services/Declarations.Service";
import CompaniesService from "../services/Company.Service";
// import QuizQuestionService from "../services/Quiz.Questions.Service";
import WaitList from "../services/Wait.List.Service";
import Auth from "../services/Auth.Service";
import Company from "../services/Company.Service";
import Modules from "../services/Modules.Service";
import AlertsService from "../services/Alert.Service";
import TaxInvoiceService from "../services/TaxInvoice.Service";
import Tool from "../services/Tool.Service";
import QuizAnswersService from "../services/QuizAnswers.Service";
import ProjectsService from "../services/Projects.Service";
import ProjectHoursService from '../services/ProjectHours.Service';
import ProjectUsersService from "../services/ProjectUsers.Service";
const allServices = {
	declarations:    () => new DeclarationsService(),
	alerts:          () => new AlertsService(),
	users:           () => new UserService(),
	taxInvoices:   	 () => new TaxInvoiceService(),
	documents:       () => new DocumentService(),
	onboardings:     () => new OnboardingService(),
	quiz:            () => new QuizService(),
	quizAnswers:     () => new QuizAnswersService(),
	claims:          () => new ClaimsService(),
	'wait.list':     () => new WaitList(),
	auth:			 () => new Auth(),
	companies:       () => new Company(),
	modules:         () => new Modules(),
	tools:           () => new Tool(),
	company:         () => new CompaniesService(),
	projects:        () => new ProjectsService(),
	projectHours:	 () => new ProjectHoursService(),
	projectUsers:	 () => new ProjectUsersService(),
};

const useService = (service) => {
    if (allServices[service]) {
        return allServices[service]();
    }

    return null;
};

export default useService;
