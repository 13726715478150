import { Button } from "react-bootstrap";
import ButtonOw from "../ButtonOw";

export default function Card({ actions, children }) {
    return (

        <div className="card">
            {actions && <div className="hover">
                {actions.map((item, i) => {
                    return <ButtonOw key={i} variant='primary' onClick={item.action}>{item.label}</ButtonOw>
                })
                }
            </div>}
            {children}
        </div>
    );
}