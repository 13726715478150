
import { Col, Row, Tooltip, Tab, Tabs, Modal, OverlayTrigger, Dropdown, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "@components/ButtonOw";
import PaginationOw from '@components/PaginationOw'
import Loading from '@components/Loading'
import { toast } from "react-toastify";
import { formatDate } from "../../../../helpers";

export default function ProjectDetails() {
    const params = useParams()
    const projects = useService('projects');
    const projectHours = useService('projectHours');
    const projectUsers = useService('projectUsers');
    const [page, setPage] = useState(1)
    const [showMassActions, setShowMassActions] = useState(false);

    const [loadingSave, setLoadingSave] = useState(false)
    const [project, setProject] = useState();
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [removeUserId, setRemoveUserId] = useState()
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showNewUser, setShowUser] = useState(false);
    const handleCloseUser = () => setShowUser(false);
    const handleShowUser = () => setShowUser(true);

    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };

    useEffect(() => {
        fetchProject();
    }, []);

    const [users, setUsers] = useState(null)
    const selectShareEmployee = (e) => {
        if (e) {
            const ev = []
            e.map((item) => {
                ev.push(item.id)
            })
            setUsers(ev);
        }
    }


    async function fetchProject() {
        try {
            // Executa ambas as requisições em paralelo e aguarda o resultado de ambas
            const [projectResult, hoursResult] = await Promise.all([
                projects.get(params.id),
                projectHours.list(`project_id=${params.id}`)
            ]);


            setProject(projectResult.data);
            setPaginationData(hoursResult.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao carregar dados:", error);
            toast.error("Erro ao carregar dados do projeto");
            setLoading(false);
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }

    function showModalDelete(id) {
        setRemoveUserId(id)
        handleShow()
    }

    async function removeUser() {
        setLoadingSave(true)
        try {
            const res = await projectUsers.delete(project.id, removeUserId)
            handleClose()
            fetchProject()
        } catch (err) {
            toast.error(err)
        }
        setLoadingSave(false)
    }
    async function addUsers() {
        setLoadingSave(true)
        try {
            const res = await projectUsers.addUser(project.id, users)
            toast.success('Projeto atualizado com sucesso')
            handleCloseUser()
            fetchProject()
        } catch (err) {
            toast.error(err)
        }
        setLoadingSave(false)
    }

    useEffect(() => {
        getRefreshHours()
    }, [page]);

    async function getRefreshHours() {
        setLoading(true)
        try {
            const result = await projectHours.list(`page=${page}`);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }
    }
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            // Select all items
            const allIds = paginationData.list.map(item => item.id);
            setSelectedIds(allIds);
        } else {
            // Deselect all items
            setSelectedIds([]);
        }
    };
    const handleSelectItem = (id, checked) => {
        if (checked) {
            // Add the ID to selected items if not already included
            setSelectedIds(prev => [...prev.filter(item => item !== id), id]);
        } else {
            // Remove the ID from selected items
            setSelectedIds(prev => prev.filter(item => item !== id));
        }
    };
    useEffect(() => {
        setShowMassActions(selectedIds.length > 0);
    }, [selectedIds]);
    useEffect(() => {
        const selectAllCheckbox = document.getElementById('selectAll');
        if (selectAllCheckbox) {
            selectAllCheckbox.checked =
                selectedIds.length > 0 && selectedIds.length === paginationData.list.length;
            selectAllCheckbox.indeterminate =
                selectedIds.length > 0 && selectedIds.length < paginationData.list.length;
        }
    }, [selectedIds, paginationData.list]);

    const handleMassApprove = async () => {
        if (selectedIds.length === 0) return;
        try {
            const body = {
                "hours": selectedIds,
                "approve": true
            }
            const res = await projectHours.approve(body)
            toast.success('Horas aprovadas com sucesso!')
            setSelectedIds([]);
            getRefreshHours()
        } catch (err) {
            toast.error(err)
        }

    };

    const handleMassReject = async () => {
        if (selectedIds.length === 0) return;
        try {
            const body = {
                "hours": selectedIds,
                "approve": false
            }
            const res = await projectHours.approve(body)
            toast.success('Horas reprovadas com sucesso!')
            setSelectedIds([]);
            getRefreshHours()
        } catch (err) {
            toast.error(err)
        }
    };
    const approveOrReproveHours = (id, approve) => {

        try {
            const body = {
                "hours": [id],
                "approve": approve
            }
            const res = projectHours.approve(body)
            toast.success(`Horas ${approve ? 'aprovadas' : 'reprovadas'} com sucesso!`)
            getRefreshHours()
        } catch (err) {
            toast.error(err)
        }
    };



    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={8}>
                    <a href="/c/projects" className="mb-4">Voltar</a>
                    <h2 className="mt-4">{project?.name}</h2>

                </Col>
                <Col md={4}>
                    <Container className="float-right">
                        <Row className="g-2">
                            <Col md={5}>
                                {/* <ButtonOw variant="white" className=" mt-4 me-2" onClick={() => generateReport()}> Relatório</ButtonOw> */}
                            </Col>
                            <Col>
                                <ButtonOw variant="primary" className=" mt-4 ms-2" onClick={() => handleShowUser()}>Adicionar colaborador</ButtonOw>
                            </Col>
                        </Row>
                    </Container>



                </Col>
            </Row>



            <Row className="mt-4">
                {loading ? loadingPage() :
                    (
                        <Tabs
                            defaultActiveKey="hours"
                            id="uncontrolled-tab-example"
                            className="mb-3">
                            <Tab
                                eventKey="hours"
                                title="Horas">
                                <Row className="mb-3">
                                    <Col>
                                        {showMassActions && (
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="mass-actions-dropdown" className="text-sm fs-6">
                                                    Ações em massa ({selectedIds.length})
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={handleMassApprove} className="text-success">
                                                        <svg
                                                            width={16}
                                                            className="me-2"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                        >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                        </svg>
                                                        Aprovar selecionados
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={handleMassReject} className="text-danger">
                                                        <svg
                                                            width={16}
                                                            className="me-2"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                        >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                        </svg>
                                                        Reprovar selecionados
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </Col>
                                </Row>
                                <Table hover className="rounded-table">
                                    <thead>
                                        <tr>
                                            <th> <input class="form-check-input" type="checkbox" value="selectAll" id="selectAll"
                                                onChange={handleSelectAll} /></th>
                                            <th>Criado por</th>
                                            <th>Descrição</th>
                                            <th>Início</th>
                                            <th>Fim</th>
                                            <th>Total de Horas</th>
                                            <th style={{ width: '250px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginationData.list.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td> <input class="form-check-input" type="checkbox" value={item.id}
                                                        id={`item-${item.id}`}
                                                        checked={selectedIds.includes(item.id)}
                                                        onChange={(e) => handleSelectItem(item.id, e.target.checked)} /></td>
                                                    <td>{item.user.name}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.init_date_time}</td>
                                                    <td>{item.end_date_time}</td>
                                                    <td>{item.total_hours}</td>
                                                    <td>
                                                        {item.status === 'WAITING' ? <Col className="action-buttons">
                                                            <OverlayTrigger placement="top" overlay={tooltip("Aprovar")}>
                                                                <a href="#" className="text-success" onClick={() => approveOrReproveHours(item.id, true)}>
                                                                    <svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                                    </svg>

                                                                </a>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={tooltip("Reprovar")} >
                                                                <a href="#" className="delete" onClick={() => approveOrReproveHours(item.id, false)}>
                                                                    <svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                    </svg>

                                                                </a>
                                                            </OverlayTrigger>
                                                        </Col> : item.status === 'APPROVED' ? <span className="text-success">Aprovado</span> : <span className="text-danger">Reprovado</span>}

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab
                                eventKey="users"
                                title="Colaboradores">
                                <Table hover className="rounded-table">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Email</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {project?.users.map((emp, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{emp.name}</td>
                                                    <td>{emp.email}</td>
                                                    <td>
                                                        <a href="#" className='delete' onClick={() => showModalDelete(emp.id)}>
                                                            <svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Tab>

                        </Tabs>
                    )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center mb-4">

                        <h5 className="mb-4 mt-3">Você tem certeza que <br />deseja remover esse usuário do projeto {project?.name}?</h5>
                        <ButtonOw variant="danger" onClick={() => removeUser()} loading={loadingSave}>
                            Confirmar
                        </ButtonOw>
                    </Modal.Body>
                </Modal>
                <Modal show={showNewUser} onHide={handleCloseUser}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-left mb-4">

                        <h5 className="mb-4 mt-3">Selecione abaixo os colaboradores que deseja adicionar a esse projeto.</h5>
                        <Form.Group controlId="validationCustom02" className="mb-4">
                            <Form.Label className="mx-2">Selecione o(s) colaborador(es)</Form.Label>
                            <SelectEmployee className={'mt-4'} name="employee1" onChange={selectShareEmployee} />
                        </Form.Group>
                        <ButtonOw variant="primary" loading={loadingSave} onClick={() => addUsers()}>
                            Salvar
                        </ButtonOw>
                    </Modal.Body>
                </Modal>
            </Row>
        </div >
    );
}
