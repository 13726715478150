import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, linkWithPopup, signOut, verifyIdToken } from "firebase/auth";
import { collection, addDoc, updateDoc, deleteDoc, doc, Timestamp, query, orderBy, onSnapshot } from "firebase/firestore";

export default class Firebase {
	firebaseConfig = {
		apiKey: "AIzaSyDDi3I2JdxibivJUzUQzRsAheMWfQUgmWs",
		authDomain: "app-onework.firebaseapp.com",
		projectId: "app-onework",
		storageBucket: "app-onework.appspot.com",
		messagingSenderId: "80359938855",
		appId: "1:80359938855:web:70203632248c37afe86702",
	};
	auth;
	app;
	db;

	constructor() {
		this.app = initializeApp(this.firebaseConfig);
		this.auth = getAuth(this.app);
		this.googleProvider = new GoogleAuthProvider();
		this.db = getFirestore(this.app);
		this.user = null;

		this.googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

		onAuthStateChanged(this.auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/auth.user
				this.user = user;
				// ...
			} else {
				// User is signed out
				// ...
				this.user = null;
			}
		});
	}

	async getServerTime() {
		// return await Timestamp.now().toDate();
		return new Date();
	}

	async createUser(email, pass) {
		try {
			return await createUserWithEmailAndPassword(this.auth, email, pass);
		} catch (err) {
			console.log(err);
			return false;
		}
	}


	async logoutUser() {
		try {
			return await signOut();
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	async loginUser(email, pass) {
		try {
			return await signInWithEmailAndPassword(this.auth, email, pass);
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	async create(path, data) {
		try {
			return await addDoc(collection(this.db, path), data);
		} catch (err) {
			console.log(err);
		}
	}

	async me() {
		return this.user;
	}

	async remove(path, id) {
		try {
			const docRef = doc(this.db, path, id);
			return await deleteDoc(docRef);
		} catch (err) { }
	}

	async update(path, data, id) {
		try {
			const docRef = doc(this.db, path, id);
			return await updateDoc(docRef, data);
		} catch (err) { }
	}

	async get(path) {
		try {
		} catch (err) { }
	}

	async list(path, query, order) {
		try {
			const ref = collection(this.db, path);
			const q = query(ref, orderBy(order.field, order.dir));
			onSnapshot(q, (querySnapshot) => {
				// setTasks(
				// 	querySnapshot.docs.map((doc) => ({
				// 		id: doc.id,
				// 		data: doc.data(),
				// 	}))
				// );
			});
		} catch (err) { }
	}
}
