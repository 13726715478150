
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import { download, formatDate } from '../../../../helpers'
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import { toast } from "react-toastify";
import Loading from '@components/Loading'
import PaginationOw from '@components/PaginationOw'
// import { formatDate } from "../../../../helpers";

export default function DocumentsEmployee() {
    const documentsService = useService('documents');
    const user = JSON.parse(localStorage.getItem('user_ow'))
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [loadingView, setLoadingView] = useState(false)
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        search: '',
    })
    const [viewSheet, setViewSheet] = useState(false)
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {
        if (form.search.length > 0) {
            search()
        } else {
            GetDocuments();
        }

    }, [page]);

    async function GetDocuments() {
        setLoading(true)
        try {
            const result = await documentsService.list('sharedUsers=' + user.id + '&page=' + page);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }

    }

    async function downloadFile(filename) {
        try {
            const searchForFile = filename.split('/').reverse()[0];
            const file = await documentsService.getDocument(searchForFile);
            // window.open(file)

            const href = URL.createObjectURL(file);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', searchForFile); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (err) {
            console.log(err);
        }
    }

    async function search() {

        if (form.search.length > 0) {
            setLoading(true)
            try {
                const result = await documentsService.list('sharedUsers=' + user.id + '&page=' + page + '&filename=' + form.search);
                setPaginationData(result.data)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                toast.error(err)
            }
        } else {
            GetDocuments()
        }

    }

    function NothingFound() {
        return (
            <div>
                <NotFound text={'Não encontramos nenhum documento.'} />
            </div>
        );
    }

    function ListAndPaginate() {
        return (
            <Table hover className="rounded-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Data de upload</th>
                        <th style={{ width: '250px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.filename}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td>
                                    <Col className="action-buttons">
                                        <OverlayTrigger placement="top" overlay={tooltip("Download")}>
                                            <a href="#" onClick={() => downloadFile(item.documentURI)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </Table>
        );
    }
    function ListColumns() {
        return (
            paginationData.list.map((item, index) => {
                return (
                    <Col md={2} lg={2} xl={2} className='mb-4'>
                        <Card actions={[{
                            label: 'Baixar',
                            action: () => downloadFile(item.documentURI)
                        }]}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>

                            <div className="card-bottom">
                                <Row>
                                    <Col>
                                        <h6>{item.filename}</h6>
                                        <p>Upload at:{formatDate(item.updatedAt)}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>)
            })
        )


    }
    function changeView() {
        setLoadingView(true)
        const timer = setTimeout(() => {
            setViewSheet(!viewSheet)
            setLoadingView(false)
        }, 1500);
        return () => clearTimeout(timer);

    }

    function handleChange(e) {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    return (
        <div className="dashboard mt-5">
            {loading ?
                <div className="d-flex justify-content-center align-itens-center">
                    <Loading variant='secondary' />
                </div>
                : <>
                    <Row>
                        <Col md={12}>
                            <h2>Documentos</h2>
                            <p>Visualize a organize melhor os documentos e contratos com empresas que você presta serviço.</p>
                        </Col>

                    </Row>

                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                                <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" defaultValue={form.search} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <ButtonOw type={'button'} variant={'secondary'} onClick={search}>Filtrar</ButtonOw>
                        </Col>
                        <Col md={2}>
                            {!viewSheet ?
                                <OverlayTrigger placement="left" overlay={tooltip("Visualizar como Tabela")}>
                                    <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg
                                                width={18}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="size-6">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger placement="left" overlay={tooltip("Visualizar como Card")}>
                                   <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg 
                                            width={18}
                                            xmlns="http://www.w3.org/2000/svg" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke-width="1.5" 
                                            stroke="currentColor" 
                                            class="size-6">
                                                <path 
                                                stroke-linecap="round" 
                                                stroke-linejoin="round" 
                                                d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                            }
                        </Col>
                    </Row >

                    <Row className="mt-4">
                        {loadingView ? <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                            <Loading variant='secondary' />
                        </div> : paginationData.totalRegisters === 0 ? NothingFound() : viewSheet ? ListAndPaginate() : ListColumns()}
                    </Row>
                    <Row>
                        <Col md={12} className='d-flex justify-content-center align-itens-center'>
                            <PaginationOw totalPages={paginationData.totalPages} currentPage={page} onClick={setPage} />
                        </Col>
                    </Row>
                </>}
        </div >
    );
}
