import { useEffect, useState } from 'react';
import { Col, Row, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Card from '@components/Card';
import DeclarationGraph from '@components/Graph';
import ButtonOw from '@components/ButtonOw';
import Tour from '@components/Tour';
import useService from '@hooks/useService';
import Loading from '@components/Loading';

export default function Dashboard() {
    const employeeService = useService('users');
    const claimsService = useService('claims');
    const quizService = useService('quiz');
    const declarationService = useService('declarations');

    const [totalEmp, setTotalEmp] = useState(0);
    const [loadingEmp, setLoadingEmp] = useState(true);
    const [subModal, setSubModal] = useState(false);
    const [totalClaims, setTotalClaims] = useState(0);
    const [loadingClaims, setLoadingClaims] = useState(true);
    const [totalQuiz, setTotalQuiz] = useState(0);
    const [loadingQuiz, setLoadingQuiz] = useState(true);
    const [declarationGraphData, setDeclarationGraphData] = useState({});
    const [loadingGraphDeclarations, setLoadingGraphDeclarations] = useState(true);
    const [primaryColor, setPrimaryColor] = useState();
    const [secondaryColor, setSecondaryColor] = useState();

    useEffect(() => {
        Promise.all([fetchEmployees(), fetchQuiz(), getTotalClaims(), getCompanyDelarationGraphData()]);
        const { companyData } = JSON.parse(localStorage.getItem('user_ow'));
        if (companyData && companyData.colors) {
            if (companyData.colors.primary) {
                setPrimaryColor(companyData.colors.primary);
            }
            if (companyData.colors.secondary) {
                setSecondaryColor(companyData.colors.secondary);
            }
        }
    }, []);

    async function fetchEmployees() {
        const result = await employeeService.list(`role=EMP`);
        setTotalEmp(result.data.totalRegisters);
        setLoadingEmp(false);
    }

    async function fetchQuiz() {
        const result = await quizService.list();
        if (result) {
            setTotalQuiz(result.data.totalRegisters);
        }
        setLoadingQuiz(false);
    }
    async function getTotalClaims() {
        const result = await declarationService.count();
        setTotalClaims(result.data.count ? result.data.count : 0);
        setLoadingClaims(false);
    }

    async function getCompanyDelarationGraphData() {
        try {
            const result = await declarationService.getCompanyGraphData();
            setDeclarationGraphData(result.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingGraphDeclarations(false);
        }
    }

    return (
        <div className="dashboard mt-5">
            {/* <Tour initialRun={true} type={'HOME'} /> */}
            <Modal show={subModal}>
                <Modal.Body className="text-center mb-4">
                    <h3 className="mt-3">Parece que seu período de testes acabou!</h3>
                    <img
                        src="/finish.svg"
                        alt="finish"
                        width={250}
                    />
                    <h5 className="mb-4">Gostaria de continuar utilizando nossa plataforma?</h5>
                    <div className="d-flex align-center justify-content-center">
                        <ButtonOw
                            variant="link"
                            onClick={() => {
                                window.location.href = '/logout';
                            }}>
                            Sair
                        </ButtonOw>
                    </div>
                </Modal.Body>
            </Modal>
            <Row className="painel">
                <Col
                    md={4}
                    xs={12}
                    sm={12}
                    className="mb-3">
                    <Card>
                        <div className="card-colaboradores">
                            {loadingEmp ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/c/employees', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>
                                    <h2>{totalEmp}</h2>
                                    <p>Colaboradores</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col
                    md={4}
                    xs={12}
                    sm={12}
                    className="mb-3">
                    <Card>
                        <div className="card-questionarios">
                            {loadingQuiz ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/c/quiz', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>
                                    <h2>{totalQuiz}</h2>
                                    <p>Questionários</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col
                    md={4}
                    xs={12}
                    sm={12}
                    className="mb-3">
                    <Card>
                        <div className="card-satisfacao">
                            {loadingClaims ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/c/declarations', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>
                                    <h2>{totalClaims}</h2>
                                    <p>incidente(s) de reclamação</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>

                {/* <Col>
                    <Card>
                        <div className="card-satisfacao">
                            <h2>0</h2>
                            <p>Pendência(s) com notas físcais</p>
                        </div>
                    </Card>
                </Col> */}
            </Row>
            {loadingGraphDeclarations ? (
                <></>
            ) : (
                <Row className="mt-4">
                    {declarationGraphData.types && (
                        <Col
                            md={6}
                            lg={6}>
                            <div className="card-graph">
                                <DeclarationGraph
                                    primary={primaryColor}
                                    secondary={secondaryColor}
                                    steps="2"
                                    title="Declarações por tipo"
                                    series={Object.values(declarationGraphData.types)}
                                    labels={Object.keys(declarationGraphData.types)}></DeclarationGraph>
                            </div>
                        </Col>
                    )}

                    {declarationGraphData.status && (
                        <Col
                            md={6}
                            lg={6}>
                            <div className="card-graph">
                                <DeclarationGraph
                                    primary={primaryColor}
                                    secondary={secondaryColor}
                                    title="Declarações por status"
                                    series={Object.values(declarationGraphData.status)}
                                    labels={Object.keys(declarationGraphData.status)}></DeclarationGraph>
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
}
