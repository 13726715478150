import BaseService from "./Base.Service";

class AlertsService extends BaseService {
	constructor() {
		const model = {
			text: { type: String, required: true },
			sharedWith: { type: String, required: true },
			sharedTeams: { type: String, required: true }
		};

		super("alerts", model);
	}

    async getListEmpDash() {
        try {
            const { data } = await this.conn.axios.get(`/alerts/get-list-dash-emp`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
}

export default AlertsService;
