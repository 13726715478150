import DefaultDatabase from '../providers/databases/Default.Database';
import * as Validator from '../providers/validator/Validator';
import { toast } from 'react-toastify';

class BaseService {
    conn;

    constructor(path, model = null) {
        this.ROLE_ADMIN = 'ADM';
        this.ROLE_AUXILIAR = 'AUX';
        this.ROLE_EMPLOYEE = 'EMP';

        this.path = path;
        this.model = model;
        this.conn = new DefaultDatabase("API").base;  //API OU FB
        // this.user = this.conn.me();
    }

    sendError(msg, status, data = []) {
        toast.error(msg);
        return {
            error: true,
            msg: msg.toString(),
            status: status || 422,
            data
        }
    }

    sendSuccess(data, msg = 'operação concluída com sucesso!') {
        toast.success(msg);
        return {
            msg: msg.toString(),
            data
        }
    }

    validateValue(field, value, validations) {
        let errorList = [];
        if (validations) {
            for (let a in validations) {
                let isValid = Validator.Validator[validations[a]](value, field);
                if (!isValid.test) {
                    errorList.push(isValid.msg)
                }
            }
        }
        return errorList;
    }

    setInModel(data, update = false) {
        let parsedData = {};
        let errors = [];
        for (let i in this.model) {
            let checkValidationErrors = this.validateValue(i, data[i], this.model[i].validations);
            if (checkValidationErrors.length > 0) {
                errors = checkValidationErrors;
            } else {
                if (!data[i] && this.model[i].default) {
                    parsedData[i] = this.model[i].default;
                } else {
                    parsedData[i] = data[i] ? data[i] : null;
                }
            }
        }

        return {
            error: errors.length > 0 ? true : false,
            data: errors.length > 0 ? errors : parsedData
        };
    }

    async create(data) {
        try {
            // const parsedData = this.setInModel(data);
            // if (parsedData.error) {
            //     console.log(parsedData.data);
            //     throw new Error(JSON.stringify(parsedData.data));
            // }

            // parsedData.data.createdAt = await this.conn.getServerTime();
            // parsedData.data.updatedAt = null;
            // parsedData.data.deletedAt = null;
            const response = await this.conn.create(this.path, data);
            return response.data
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }

    async update(data, id) {

        try {
            // const parsedData = this.setInModel(data, true);
            // parsedData.data.updatedAt =  await this.conn.getServerTime();
            const response = await this.conn.update(this.path, data, id);
            if (response.data) {
                // toast.success("registro atualizado com sucesso!");
            }

            return response.data
        } catch (err) {
            console.log(err.status);
        }
    }

    list(query = '', select = null) {
        try {
            return this.conn.list(this.path, query);
        } catch (err) {
            console.log(err);
        }
    }

    listAll(query = '', select = null) {
        try {
            return this.conn.listAll(this.path, query);
        } catch (err) {
            console.log(err);
        }
    }

    count(query = '', select = null) {
        try {
            return this.conn.count(this.path, query);
        } catch (err) {
            console.log(err);
        }
    }

    async delete(id) {
        try {
            return this.conn.destroy(this.path, id);
        } catch (err) {
            console.log(err);
        }
    }

    async restroy(id) {
        try {
            return this.conn.restroy(this.path, id);
        } catch (err) {
            console.log(err);
        }
    }

    async destroy(id) {
        try {
            return this.conn.destroy(this.path, id);
        } catch (err) {
            console.log(err);
        }
    }

    get(id) {
        try {
            return this.conn.get(this.path, id);
        } catch (err) {
            console.log(err)
        }
    }
}

export default BaseService;