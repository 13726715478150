import Firebase from "./Firebase";
import Axios from "./Axios";

class DefaultDatabase {
	constructor(base = "FB") {
		if (base === 'FB') {
			this.base = new Firebase();
		} else {
			this.base = new Axios();
		}
	}
}

export default DefaultDatabase
