import { Col, Row } from "react-bootstrap";

import { Form, InputGroup, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "../../../../components/ButtonOw";
import { toast } from "react-toastify";
import Card from '@components/Card'
export default function NewQuiz() {
	const quizService = useService('quiz');
	const [loadingSave, setLoadingSave] = useState(false)
	const [questionnarie, setQuestionnarie] = useState({
		title: '',
		status: 'ATIVO',
	})
	const [questions, setQuestions] = useState([
		{
			description: '',
			questionType: '',
			options: [{
				title: '',
				isCorrect: false
			}],
		},
	]);

	const addQuestion = () => {
		setQuestions((prevQuestions) => [
			...prevQuestions,
			{
				description: '',
				questionType: '',
				options: [{
					title: '',
					isCorrect: false
				}],
			},
		]);
	};


	const handleDescriptionChange = (index,
		event) => {
		const updatedQuestions = [...questions];
		updatedQuestions[index].description = event.target.value;
		setQuestions(updatedQuestions);
	};

	const handleTypeChange = (index,
		event) => {
		const updatedQuestions = [...questions];
		updatedQuestions[index].questionType = event;
		setQuestions(updatedQuestions);
	};

	const handleOptionChange = (questionIndex, optionIndex, event) => {
		const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].options[optionIndex].title = event.target.value;
		setQuestions(updatedQuestions);
	};

	const setOptionCorrect = (questionIndex, optionIndex, event) => {
		const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].options[optionIndex].isCorrect = !updatedQuestions[questionIndex].options[optionIndex].isCorrect;
		setQuestions(updatedQuestions);
	};

	const addOption = (questionIndex) => {
		const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].options.push({
			title: '',
			questionType: 'multi',
			isCorrect: false
		});
		setQuestions(updatedQuestions);
	};
	const removeQuestion = (questionIndex) => {
		const updatedQuestions = [...questions];
		updatedQuestions.splice(questionIndex, 1)
		setQuestions(updatedQuestions);
	};
	const removeOption = (questionIndex, index) => {
		const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].options.splice(index, 1)
		setQuestions(updatedQuestions);
	};

	async function create() {
		setLoadingSave(true)
		try {
			const body = questionnarie
			body.questions = questions

			const res = await quizService.create(body)
			if (res.error) {
				toast.error(res.msg)
			} else {
				toast.success('Questionário criado com sucesso')
				const timer = setTimeout(() => {
                    window.location.href = '/c/quiz'
                }, 3000);
			}


		} catch (err) {
			toast.error(err)
		}

	}

	return (
		<div className='quiz'>
			<Row>
				<Col md={10}>
					<a href="/c/quiz">Voltar</a>
					<h1>Novo questionário</h1>
				</Col>
				<Col>
					<ButtonOw loading={loadingSave} type='button' variant="primary" onClick={() => create()}>
						Salvar
					</ButtonOw>
				</Col>
			</Row>



			<Row>
				<Col md={12} className='mb-2 mt-4'>
					<Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
						<Form.Label className="mb-3">Título do questionário:</Form.Label>
						<Form.Control required type="text" placeholder="" name="title" value={questionnarie.title}
							onChange={(e) => setQuestionnarie((prevState) => ({
								...prevState,
								title: e.target.value
							}))} className='input' />
					</Form.Group>
				</Col>
			</Row>
			{questions.map((question, index) => (
				<div className='mt-3' key={index}>

					<Card key={index} className='mt-5 mb-5'>
						<div className="remove-question">
							<button onClick={() => removeQuestion(index)}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
									<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
								</svg>
							</button>
						</div>
						<Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
							<Form.Label className="mb-3">Pergunta:</Form.Label>
							<Form.Control required type="text" placeholder="" name="description" value={question.description}
								onChange={(e) => handleDescriptionChange(index, e)} className='input' />
						</Form.Group>
						<div key={`inline-radio` + index} className="mb-3">
							<Form.Label style={{ marginRight: 20 }} className='mb-3'>Tipo de resposta:</Form.Label><br />
							<Form.Check
								inline
								label="Escrita"
								defaultChecked={questions[index].questionType === 'desc'}
								name={"type" + index}
								type={'radio'}
								onClick={(e) => handleTypeChange(index, 'desc')}
								id={`inline-radio-` + index}
							/>
							<Form.Check
								inline
								label="Múltipla escolha"
								defaultChecked={questions[index].questionType === 'multi'}
								name={"type" + index}
								type={'radio'}
								onClick={(e) => handleTypeChange(index, 'multi')}
								id={`inline-radio-2-` + index}

							/>
						</div>
						{question.questionType === 'multi' &&
							<>
								<Form.Label style={{ marginRight: 20 }}>Opções:</Form.Label><br />
								<ul>
									{question.options.map((option, optionIndex) => (
										<li key={optionIndex}>
											<Col md={6}>
												<Form.Group className="mb-3 d-flex">
													<Form.Control required type="text" placeholder="" name="title" value={option.title}
														onChange={(e) => handleOptionChange(index, optionIndex, e)} className='input' />

													{option.isCorrect ? <ButtonOw variant="success" style={{ width: 180 }} id="button-addon2" onClick={(e) => setOptionCorrect(index, optionIndex, e)}>
														Correta</ButtonOw> : <ButtonOw variant="secondary" style={{ width: 180 }} id="button-addon2" onClick={(e) => setOptionCorrect(index, optionIndex, e)}>
														Errada</ButtonOw>}
													{question.options.length === optionIndex + 1 ? <button className="add-options" onClick={() => addOption(index)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
														<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													</button> : <button className="add-options" onClick={() => removeOption(index, optionIndex)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
														<path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													</button>}

												</Form.Group>

											</Col>
										</li>
									))}
								</ul>

							</>}

					</Card>
				</div>
			))
			}

			<div className="mt-3">
				<ButtonOw type='button' variant="secondary" onClick={addQuestion} style={{ width: '100%' }}>Adicionar Pergunta</ButtonOw>

			</div>
			<div className="mt-2 mb-3">
				<ButtonOw loading={loadingSave} type='button' variant="primary" onClick={() => create()} style={{ width: '100%' }}>
					Salvar questionário
				</ButtonOw>
			</div>

		</div >
	);
}

