import { Col, Row, Modal } from "react-bootstrap";
import { Link, Outlet, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "@components/ButtonOw";
import { toast } from "react-toastify";
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import SelectType from "./SelectType";
import Content from "./Content";
import NameStep from "./NameStep";
import Tour from "@components/Tour";
export default function NewOnboarding() {
	const onboardingsService = useService('onboardings');
	const params = useParams()
	const [order, setOrder] = useState(1)
	const [show, setShow] = useState(false);
	
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [newStep, setNewStep] = useState({
		name: '',
		content: '',
		contentType: ''
	})
	const [onboarding, setOnboarding] = useState(
	);
	const [steps, setSteps] = useState([
		// {
		// 	id: 1,
		// 	name: 'Bem-vindo',
		// 	contentType: 'text',
		// 	content: 'Seja muito bem vindo a empresa, estamos muito contentes em te receber aqui. Aqui estão os próxmos passos para sua jornada.'
		// },
	]);

	useEffect(() => {
		getOnboarding()
	}, [])

	async function getOnboarding() {
		try {
			const res = await onboardingsService.get(params.id)
			setOnboarding(res.data)
			setSteps(res.data.steps)
		} catch (err) {
			toast.error(err)
		}
	}
	async function addStep() {
		const obj = newStep
		obj.id = onboarding.length + 1
		obj.onboardingId = params.id
		const res = await onboardingsService.addStep(obj)
		setSteps((prevOnboarding) => [
			...prevOnboarding,
			obj
		]);
		handleClose()
		setOrder(1)
	}
	function handleChange(name, value) {
		setNewStep((prevState) => ({
			...prevState,
			[name]: value
		}));
	}

	return (
		<div className="mt-5">
			{/* <Tour initialRun={true} type={'ONBOARDINGCREATE'}/> */}
			<Row>
				<Col md={10}>
					<a href="/c/onboarding">Voltar</a>
					<h2 className="mt-3">Seu Onboarding</h2>
					<p>Cadastre textos, imagens e vídeos para seu onboarding.</p>
				</Col>


			</Row>

			<Row>
				<Col md={12} xs={12} sm={12}>
					<ButtonOw variant="primary" type='button' onClick={handleShow}>
						Adicionar etapa
					</ButtonOw>
					<ReactSortable list={steps} setList={setSteps}>
						{steps.map((item) => (
							<div className="step" key={item.id}>
								<div>
									{item.name}
								</div>
								<div>
									{item.contentType}
								</div>
							</div>
						))}
					</ReactSortable>
				</Col>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body className="p-4 text-center">
						{order === 1 && <NameStep setName={handleChange} />}
						{order === 2 && <SelectType setType={handleChange} />}
						{order === 3 && <Content contentType={newStep.contentType} setContent={handleChange}  />}
						<div className='mt-5'>
							{order > 1 && <ButtonOw variant="white" onClick={() => setOrder(order - 1)}>
								Voltar
							</ButtonOw>}
							<ButtonOw variant="secondary" onClick={() => order === 3 ? addStep() : setOrder(order + 1)}>
								Próximo
							</ButtonOw>
						</div>

					</Modal.Body>
				</Modal>
			</Row>

		</div>
	);
}
