import { Col, Row, OverlayTrigger, Tooltip, Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonOw from '@components/ButtonOw';
import Card from '@components/Card';
import Confetti from 'react-dom-confetti';
import vars from '../../../../vars.module.scss';
import { toast } from 'react-toastify';
import { formatDate } from '../../../../helpers';

import useService from '@hooks/useService';

const config = {
    angle: 120,
    spread: 153,
    startVelocity: 45,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: '5px',
    height: '5px',
    perspective: '500px',
    colors: [vars.primary, vars.secondary, vars.primary_pastel, vars.terciary, vars.pink, vars.primary],
};

export default function QuizView() {
    let { id } = useParams();
    const user = JSON.parse(localStorage.getItem('user_ow'));
    const { companyData } = user;
    const quizService = useService('quiz');
    const quizAnswersService = useService('quizAnswers');

    const [response, setResponse] = useState({});
    const [explode, setExplode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataQuiz, setData] = useState({});
    const [view, setView] = useState('quest');
    const [show, setShow] = useState(false);
    const [answers, setAnswers] = useState({});
    const [colorsStyle, setColorsStyle] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let colors = {};
    if (companyData.colors && companyData.colors.primary) {
        colors['--primary-color'] = companyData.colors.primary;
    }
    if (companyData.colors && companyData.colors.secondary) {
        colors['--secondary-color'] = companyData.colors.secondary;
    }
    if (companyData.colors && companyData.colors.textColor) {
        colors['--text-color'] = companyData.colors.textColor;
    }

    useEffect(() => {
        getQuiz();
        setColorsStyle(colors);
    }, []);

    async function getQuiz() {
        try {
            const res = await quizService.get(id);
            setData(res.data);

            if (user.role === 'ADM') {
                await getAnswers(res.data.questions);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    async function getAnswers(questions) {
        const { data: answers, error } = await quizAnswersService.listAll(`quiz=${id}&populate=createdBy`);
        let questionGroups = {};

        if (!error && questions) {
            for (let question of questions) {
                questionGroups[question.id] = answers.filter((e) => e.quizQuestion === question.id);
            }
            setAnswers(questionGroups);
        }
    }

    async function confirm() {
        let allQuestions = [];
        for (let question in response) {
            allQuestions.push({
                quiz: id,
                quizQuestion: question,
                answer: response[question],
            });
        }

        await quizAnswersService.create({ answers: allQuestions });
        toast.success('Questionário enviado');
        setShow(false);
    }

    function handleChange(id, value) {
        const obj = { ...response };
        obj[id] = value;
        setResponse(obj);
    }

    function admQuizAnswers() {
        return (
            <>
                <Row className="text-start">Respostas</Row>
                {answers.length > 0 && dataQuiz?.questions?.map((item, i) => {
                    return (
                        <Row className="text-start">
                            <Col>
                                <div className="question">
                                    <p>{item.description}</p>

                                    <details>
                                        <summary>{answers[item.id] && <div>{answers[item.id].length} respostas</div>}</summary>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th width="30%">Autor</th>
                                                    <th width="60%">Resposta</th>
                                                    <th width="10%">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.values(answers[item.id]).map((itemQuiz, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{itemQuiz.createdBy.name}</td>
                                                            <td>{itemQuiz.answer}</td>
                                                            <td>{formatDate(itemQuiz.createdAt)}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </details>
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </>
        );
    }

    return loading ? (
        <h2>Carregando..</h2>
    ) : (
        <div
            className="quizview"
            style={colorsStyle}>
            <Col>
                <img
                    src="/onework-f.png"
                    width={100}
                    className="log"
                    alt="Onework Logo"
                />
            </Col>
            {user && user.role === 'ADM' && (
                <div className="options-view">
                    <ButtonOw
                        variant={view === 'quest' ? 'pastel' : 'white'}
                        onClick={() => setView('quest')}>
                        Questionário
                    </ButtonOw>
                    <ButtonOw
                        variant={view === 'answers' ? 'pastel' : 'white'}
                        onClick={() => setView('answers')}>
                        Respostas
                    </ButtonOw>
                </div>
            )}
            <Card>
                <Row className="text-start">
                    <h3 className="mt-2 mb-4 ">{dataQuiz.title}</h3>
                </Row>
                {view === 'quest' ? (
                    <Row className="text-start">
                        {dataQuiz.questions.map((item, i) => {
                            return (
                                <div className="question">
                                    <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label
                                            style={{ marginRight: 20 }}
                                            className="mb-3">
                                            {item.description}
                                        </Form.Label>
                                        <br />

                                        {item.questionType === 'multi' ? (
                                            <div
                                                key={`inline-radio` + i}
                                                className="mb-3">
                                                {item.options.map((option, index) => {
                                                    return (
                                                        <Form.Check
                                                            inline
                                                            label={option.title}
                                                            name={i}
                                                            type={'radio'}
                                                            onClick={(e) => handleChange(item.id, option.title)}
                                                            id={`inline-radio-` + index}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                placeholder="Descreva"
                                                name="text"
                                                className=""
                                                style={{ borderRadius: '10px !important' }}
                                                onChange={(e) => handleChange(item.id, e.target.value)}
                                            />
                                        )}
                                    </Form.Group>
                                </div>
                            );
                        })}

                        <ButtonOw
                            variant="primary"
                            type="button"
                            onClick={handleShow}>
                            Enviar
                        </ButtonOw>
                    </Row>
                ) : (
                    admQuizAnswers()
                )}

                <Confetti
                    active={explode}
                    config={config}
                />
            </Card>

            <Modal
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center mb-4">
                    <img
                        alt="Onework"
                        src="/ok.svg"
                        width={300}
                    />
                    <h5 className="mb-4">Podemos enviar suas respostas?</h5>
                    <ButtonOw
                        variant="primary"
                        onClick={confirm}>
                        Confirmar
                    </ButtonOw>
                </Modal.Body>
            </Modal>
        </div>
    );
}
