import BaseService from "./Base.Service";

class TaxInvoiceService extends BaseService {
    constructor() {
        const model = {
            employee: { type: String, required: true },
            month: { type: String, required: true },
            companyId: { type: String, required: true },
            documentURI: { type: String, required: true },
        };

        super("tax-invoices", model);
    }

    async uploadTaxInvoice(formData) {
        try {
            const { data } = await this.conn.axios.post(`/tax-invoices/upload-tax-invoice`, formData, { headers : { 'Content-Type' : 'multipart/formdata' } });
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }

    async getDocument(filename, userId = null) {
        try {
            const { data } = await this.conn.axios({
                method: 'GET',
                url: `/tax-invoices/get-file?filename=${filename}&userId=${userId}`,
                responseType: 'blob'
            })
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar a nota", false, err);
        }
    }
}

export default TaxInvoiceService;
