import logo from "./logo.svg";
import "./index.scss";
import Layout from "./views/Layouts/Index";
import WaitList from "./views/Pages/Common/WaitList/Form";
import { Routes, Route, Link, } from "react-router-dom";
// import Dashboard from "./views/Pages/Company/Dashboard";
import InitialPage from "./views/Pages/Common/PanelDashboard";
import Login from "./views/Pages/Auth/index";
import SignUp from "./views/Pages/Auth/SignUp";
import EmployeeSingUp from "./views/Pages/Auth/EmployeeSingUp";
import Employees from "./views/Pages/Company/Employees";
import NFes from "./views/Pages/Company/NFes";
import Documents from "./views/Pages/Company/Documents";
import Alerts from "./views/Pages/Company/Alerts";
import UploadDocument from "./views/Pages/Company/Documents/UploadDocument";
import NFesEmployee from "./views/Pages/Employee/NFes";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OnboardingView from "./views/Pages/Common/OnboardingView"
import Claims from "./views/Pages/Company/Claims";
import Declarations from "./views/Pages/Company/Declarations";
import DocumentsEmployee from './views/Pages/Employee/Documents'
import OnboardingEmployee from './views/Pages/Employee/Onboarding'
import ClaimsEmployee from "./views/Pages/Employee/Claims";
import DeclarationsEmployee from "./views/Pages/Employee/Declarations";
import Details from "./views/Pages/Company/Employees/Details";
import Quiz from "./views/Pages/Company/Quiz";
import NewQuiz from "./views/Pages/Company/Quiz/NewQuiz";

import Auth from './providers/auth/Auth';
import EditQuiz from "./views/Pages/Company/Quiz/EditQuiz";
import QuizEmployee from "./views/Pages/Employee/Quiz";
import QuizView from "./views/Pages/Common/QuizView";
import Onboarding from "./views/Pages/Company/Onboarding";
import NewOnboarding from "./views/Pages/Company/Onboarding/New";
import NewAlert from "./views/Pages/Company/Alerts/NewAlert";
import Profile from "./views/Pages/Common/Profile";
import Config from "./views/Pages/Company/Config";
import Integrations from "./views/Pages/Common/Integrations";
import BankEmployee from "./views/Pages/Employee/Bank";
import Projects from "./views/Pages/Company/Projects";
import NewProject from "./views/Pages/Company/Projects/NewProject";
import ProjectDetails from "./views/Pages/Company/Projects/Details";
import ProjectsEmployee from "./views/Pages/Employee/Projects";
import ProjectDetailsEmployee from "./views/Pages/Employee/Projects/Details";
function App() {

	return (
		<div id="App">
			<ToastContainer />
			<Routes>
				<Route path="wait-list" element={<WaitList />} />
				<Route path="sign-in" element={<Login />} />
				<Route path="sign-up" element={<SignUp />} />
				<Route path="/register/e/:id/:company" element={<EmployeeSingUp />} />
				<Route path="/onboarding/view/:id" element={<OnboardingView />} />
				<Route path="/quiz/view/:id" element={<QuizView />} />
				<Route path="/" element={<Layout />}>
					<Route index element={<InitialPage />} />

					{/* Company Routes */}
					<Route element={<Auth allowedRoles={["ADM", "AUX"]} />}>
						<Route path="/config" element={<Config />} />
						<Route path="/c/employees" element={<Employees />} />
						<Route path="/c/projects" element={<Projects />} />
						<Route path="/c/projects/new" element={<NewProject />} />
						<Route path="/c/projects/:id" element={<ProjectDetails />} />
						<Route path="/c/employe/:id" element={<Details />} />
						<Route path="/c/nfes" element={<NFes />} />
						<Route path="/c/onboarding" element={<Onboarding />} />
						<Route path="/c/onboarding/:id" element={<NewOnboarding />} />
						<Route path="/c/documents" element={<Documents />} />
						<Route path="/c/documents/upload" element={<UploadDocument />} />
						<Route path="/c/claims" element={<Claims />} />
						<Route path="/c/declarations" element={<Declarations />} />
						<Route path="/c/alerts" element={<Alerts />} />
						<Route path="/c/alerts/new" element={<NewAlert />} />
						<Route path="/c/quiz" element={<Quiz />} />
						<Route path="/c/quiz/new" element={<NewQuiz />} />
						<Route path="/c/quiz/:id" element={<EditQuiz />} />

					</Route>


					{/* Employee Routes */}
					<Route element={<Auth allowedRoles={["EMP"]} />}>
						<Route path="/e/onboarding" element={<OnboardingEmployee />} />
						<Route path="/e/nfes" element={<NFesEmployee />} />
						<Route path="/e/projects" element={<ProjectsEmployee />} />
						<Route path="/e/projects/:id" element={<ProjectDetailsEmployee />} />
						<Route path="/e/claims" element={<ClaimsEmployee />} />
						<Route path="/e/declarations" element={<DeclarationsEmployee />} />
						<Route path="/e/documents" element={<DocumentsEmployee />} />
						<Route path="/e/quiz" element={<QuizEmployee />} />
						<Route path="/e/bank" element={<BankEmployee />} />
					</Route>

					<Route path="/profile" element={<Profile />} />
					<Route path="/integrations" element={<Integrations />} />
					<Route path="/unauthorized" element={<Unauthorized />} />
					<Route path="*" element={<NoMatch />} />
				</Route>

			</Routes>
		</div>
	);
}

function NoMatch() {
	return (
		<div>
			<h2>Nothing to see here!</h2>
			<p>
				<Link to="/">Go to the home page</Link>
			</p>
		</div>
	);
}

function Unauthorized() {
	return (
		<div>
			<h2>The path is forbidden!</h2>
			<p>
				<Link to="/">Go to the home page</Link>
			</p>
		</div>
	);
}

export default App;
