import { useState } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
export default function NameStep({ setName }) {
	function handleChange(e) {
		setName('name', e.target.value);
	}
	return (
		<Row className="newType">
			<h5>Preencha a informação necessária</h5>
			<Col>

				<Form.Group controlId="validationCustom01" className="mt-4 mb-4">
					<Form.Label className="mx-2">Título</Form.Label>
					<Form.Control type="text" placeholder="ex: Dados importantes para visualização" name="name" className="input" onChange={handleChange} />
				</Form.Group>

			</Col>

		</Row>

	);
}
