import { Button } from "react-bootstrap";
import ButtonOw from "../ButtonOw";
export default function NotFound({ text, action, actionLabel, loading = 'false' }) {

    return (
        <div className="notFound">
            <h4>{text}</h4>
            {action && <ButtonOw type={'button'} variant={'secondary'} onClick={action}>
                {actionLabel}
            </ButtonOw>}
            <br />
            <img alt="No Data" src="/not-found.avif" className="blend-img" width={400} />
        </div>

    );
}