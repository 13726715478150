import BaseService from "./Base.Service";

class DocumentService extends BaseService {
    constructor() {
        super("documents")
    }

    async uploadDocument(formData) {
        try {
            const { data } = await this.conn.axios.post(`/documents/upload-document`, formData, { headers: { 'Content-Type': 'multipart/formdata' } });
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }

    async getDocument(filename) {
        try {
            const { data } = await this.conn.axios({
                method: 'GET',
                url: `/documents/get-document?filename=` + filename,
                responseType: 'blob'
            })
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }
}

export default DocumentService;