import BaseService from "./Base.Service";
import CompanyService from "./Company.Service";

class AuthService extends BaseService {
  constructor() {
    const model = {
      name: { type: String, required: true },
      email: { type: String, required: true },
      authId: { type: String, required: true },
      companyId: { type: String, required: true },
      role: { type: String, required: true },
      avatar: { type: String },
    };

    super("users", model);

    this.companyService = new CompanyService();
  }

  async loginUser(email, pass) {
    try {
      return await this.conn.loginUser(email, pass);
    } catch (err) {
      console.log(err);
      return this.sendError("Erros foram encontrados ao salvar", false, err);
    }
  }

  async me(query = "") {
    try {
      return await this.conn.me(query);
    } catch (err) {
      console.log(err);
      return this.sendError("Erros foram encontrados ao salvar", false, err);
    }
  }

  async logoutUser() {
    try {
      localStorage.removeItem("idToken_ow");
      localStorage.removeItem("uid_ow");
      return await this.conn.logoutUser();
    } catch (err) {
      console.log(err);
      return this.sendError("Não conseguimos realizar o logout", false, err);
    }
  }

  async createNewUser(userData) {
    try {
     const response =  await this.conn.createUser(userData);
      return response.data;
    } catch (err) {
      console.log(err);
      return this.sendError("Erros foram encontrados ao salvar", false, err);
    }
  }
  async addUser(userData) {
    try {
      const response = await this.conn.addUser(userData);
      return true;
    } catch (err) {
      console.log(err);
      return this.sendError("Erros foram encontrados ao salvar", false, err);
    }
  }
  async batchAddUser(userData) {
    try {
      
      const response =  await this.conn.axios.post(`/auth/batch/add-user`, userData);
      if (!response) {
        throw new Error("Erro ao cadastrar usuário");
      }
      return true;
    } catch (err) {
      console.log(err);
      return this.sendError("Erros foram encontrados ao salvar", false, err);
    }
  }
}

export default AuthService;
