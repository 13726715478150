import { useState } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import { toast } from "react-toastify";
export default function Content({ contentType, setContent, fileForm }) {
	function handleChange(e) {
		setContent('content', e.target.value);
	}
	function uploadFile(e) {
		const file = e.target.files[0]
		var fd = new FormData();
		fd.append('file', file);
		try {
			
			setContent('content', file);
			
		} catch (err) {
			toast.error(err)
		}
	}

	return (
		<Row className="newType">
			<h5>Preencha a informação necessária</h5>
			<Col>
				{contentType === 'text' && <Form.Group className="mb-3">
					<Form.Label>Texto referente a etapa</Form.Label>
					<Form.Control as="textarea" rows={3} onChange={handleChange} />
				</Form.Group>}
				{contentType === 'video' && <Form.Group controlId="validationCustom01" className="mt-4 mb-4">
					<Form.Label className="mx-2">Link do vídeo</Form.Label>
					<Form.Control type="text" placeholder="ex: https://vimeo.com/347119375" name="filename" className="input" onChange={handleChange} />
				</Form.Group>}
				{contentType === 'image' && <Form.Group controlId="formFile" className="mb-5 mt-4 ">
					<Form.Label className="mx-2">Selecione seu arquivo</Form.Label>
					<Form.Control type="file" name='file' onChange={uploadFile} />
				</Form.Group>}
			</Col>

		</Row>

	);
}
