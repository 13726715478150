import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from '@components/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import useService from '@hooks/useService';
import Loading from '../../../../components/Loading';
import ButtonOw from '../../../../components/ButtonOw';

export default function BankEmployee() {
    const userService = useService('users');
    const user = JSON.parse(localStorage.getItem('user_ow'));
    const [edit, setEdit] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [formData, setFormData] = useState({
        pixKey: '',
        bankName: '',
        bankCode: '',
        bankAgency: '',
        bankAccount: '',
        bankAccountDigit: '',
        bankAccountType: '',
        bankFavoured: '',
        document: '',
    });
    const [loading, setLoading] = useState(true);

    async function getBankList() {
        const data = await userService.getBankNames();
        if (data.length > 0) {
            setBankList(data.filter(e => e.code));
        }
    }

    async function getCompleteUserData() {
        try {
            const { data } = await userService.get(user.id);
            const { bankData } = data;

            let defaultBankName = '';
            let defaultBankCode = '';
            if (bankList.length > 0) {
                defaultBankName = bankList[0].name;
                defaultBankCode = bankList[0].code;
            }

            const bankDataFields = {
                pixKey: bankData && bankData.pix_key ? bankData.pix_key : '',
                bankName: bankData && bankData.bank_name ? bankData.bank_name : defaultBankName,
                bankCode: bankData && bankData.bank_code ? bankData.bank_code : defaultBankCode,
                bankAgency: bankData && bankData.bank_agency ? bankData.bank_agency : '',
                bankAccount: bankData && bankData.bank_account ? bankData.bank_account : '',
                bankAccountDigit: bankData && bankData.bank_account_digit ? bankData.bank_account_digit : '',
                bankAccountType: bankData && bankData.bank_account_type ? bankData.bank_account_type : '',
                bankFavoured: bankData && bankData.bank_favoured ? bankData.bank_favoured : '',
                document: bankData && bankData.document ? bankData.document : data.document,
            };

            setFormData(bankDataFields);
            // setBankList(data.value)

            setLoading(false);
        }catch(err){
            console.log(err)
            setLoading(false)
        }
       
    }

    function handleChangeBank(evt, index) {
        handleChange(evt);
        const bankData = bankList.find(e => e.name === evt.target.value);
        setFormData({
            ...formData,
            bankCode: bankData.code,
            bankName: bankData.name,
        });
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    }

    async function submitData(event) {
        event.preventDefault();
        await userService.updateBankData(user.id, formData);
    }

    useEffect(() => {
        Promise.all([getCompleteUserData(), getBankList()]);
    }, []);

    return (
        <div className="dashboard mt-5">
            {loading ? (
                <div className="d-flex justify-content-center align-itens-center">
                    <Loading variant="secondary" />
                </div>
            ) : (
                <>
                    <Row className="">
                        <Col md={12}>
                            <h2>Financeiro</h2>
                            <p>Controle seus recebimentos e dados bancários.</p>
                        </Col>
                    </Row>

                    <Row className="mt-4 ">
                        <Col
                            md={12}
                            className="">
                            <div className="bank-info">
                                <div className="head">
                                    <h6>Dados bancários</h6>
                                    <button onClick={() => setEdit(!edit)}>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                            />
                                        </svg>
                                        Editar
                                    </button>
                                </div>

                                <Form
                                    method="POST"
                                    onSubmit={submitData}>
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Group controlId="pix">
                                                <Form.Label className="mx-2">PIX</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="pixKey"
                                                    className="input"
                                                    value={formData.pixKey}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={4}>
                                            <Form.Group controlId="bank">
                                                <Form.Label className="mx-2">Banco</Form.Label>
                                                <Form.Control
                                                    name="bankName"
                                                    as="select"
                                                    defaultValue={formData.bankName}
                                                    onChange={handleChangeBank}
                                                    disabled={!edit}>
                                                    {bankList && bankList.map((e) => <option value={e.name} selected={e.name === formData.bankName}>{e.name}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group controlId="code">
                                                <Form.Label className="mx-2">Código</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankCode"
                                                    value={formData.bankCode}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="agency">
                                                <Form.Label className="mx-2">Agência</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankAgency"
                                                    className="input"
                                                    value={formData.bankAgency}
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="account">
                                                <Form.Label className="mx-2">Conta</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankAccount"
                                                    value={formData.bankAccount}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group controlId="digit">
                                                <Form.Label className="mx-2">Dígito</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankAccountDigit"
                                                    value={formData.bankAccountDigit}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 mb-4">
                                        <Col md={3}>
                                            <Form.Group controlId="type">
                                                <Form.Label className="mx-2">Tipo de conta</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankAccountType"
                                                    value={formData.bankAccountType}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Group controlId="favoreced">
                                                <Form.Label className="mx-2">Favorecido</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bankFavoured"
                                                    value={formData.bankFavoured}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="document">
                                                <Form.Label className="mx-2">CNPJ / CPF</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="document"
                                                    value={formData.document}
                                                    onChange={handleChange}
                                                    className="input"
                                                    disabled={!edit}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {edit && (
                                        <ButtonOw
                                            type={'submit'}
                                            variant="secondary">
                                            Salvar
                                        </ButtonOw>
                                    )}

                                    {/* <ButtonOw type={'submit'} variant='primary' >Enviar novo documento</ButtonOw> */}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}
