import { Col, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import ButtonOw from "@components/ButtonOw";
import Card from "@components/Card";
import ReactPlayer from "react-player";
import Confetti from 'react-dom-confetti';
import vars from '../../../../vars.module.scss'
import data from '../../../../mock/onboarding';
import ProgressBar from 'react-bootstrap/ProgressBar';


const config = {
    angle: 120,
    spread: 153,
    startVelocity: 45,
    elementCount: 100,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "5px",
    height: "5px",
    perspective: "500px",
    colors: [
        vars.primary,
        vars.secondary,
        vars.primary_pastel,
        vars.terciary,
        vars.pink,
        vars.primary,
    ],
};
export default function OnboardingView() {
    let { id } = useParams();
    const [position, setPosition] = useState(0)
    const [progress, setProgress] = useState((data.length / 100) * 1000)
    const [porcProgress, sePorcProgress] = useState((data.length / 100) * 1000)
    const [loadingVideo, setLoadingVideo] = useState(true)
    const [numPages, setNumPages] = useState();
    const [explode, setExplode] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);

    function next() {

        setPosition((prev) => prev + 1)
        setProgress((prev) => prev < 100 ? prev + porcProgress : prev)

    }
    function previous() {

        setPosition((prev) => prev > 0 ? prev - 1 : prev)
        setProgress((prev) => position > 0 ? prev - porcProgress : prev)

    }
    return (
        <div className="onboardingview">

            <Row>

                <Col>
                    {/* <button>Voltar</button> */}
                    <img src='/ow-white.png' width={80} className='log' alt='Onework Logo' />
                    {/* <p className="mt-3">Check the onboarding</p> */}
                    <p>Page {position + 1} of {data.length}</p>
                </Col>
            </Row>
            <div className='sombra'>
                <Row className="view ">
                    <ProgressBar now={progress} className='mt-2' />
                    <Col md={1} lg={1} xl={1}>
                        <span onClick={previous}>
                            <svg xmlns="http://www.w3.org/2000/svg" height={45} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </span>



                    </Col>
                    <Col className="container">
                        {position + 1 > data.length ?
                            <>
                                <h2> Parabéns! Você chegou ao final! <br />Deseja finalizar o onboarding?</h2>
                                <ButtonOw type={'button'} variant='primary' onClick={() => setExplode(true)}> Finalizar </ButtonOw>

                            </> : <>
                                <h2>{data[position].name}</h2>
                                <div className="content">

                                    {data[position].contentType === 'image' ?
                                        <img src={data[position].content} />
                                        : data[position].contentType === 'text' ? <p>{data[position].content}</p> :
                                            data[position].contentType === 'pdf' ?
                                                <h2>PDF</h2> :
                                                <div>
                                                    {loadingVideo ? <p>carregando</p> : null}
                                                    <ReactPlayer
                                                        onReady={() => setLoadingVideo(false)}
                                                        url={data[position].content}
                                                        controls
                                                    />
                                                </div>}
                                </div></>}
                        <Confetti active={explode} config={config} />
                    </Col>
                    <Col md={1} lg={1} xl={1}>
                        {position + 1 <= data.length && <span onClick={next}>
                            <svg xmlns="http://www.w3.org/2000/svg" height={45} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </span>
                        }

                    </Col>
                </Row>
            </div>

        </div>);
}