import { Col, Row, OverlayTrigger, Tooltip, InputGroup, Badge } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import useService from "../../../hooks/useService";
import { toast } from "react-toastify";
import ButtonOw from "../../../components/ButtonOw";
import { maskDocument } from "../../../helpers";
export default function Login() {
    const authService = useService('auth');
    const [isView, setIsView] = useState(false);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [state, setState] = useState({
        pass: "",
        email: "",

    });

    function handleChange(evt) {
        const value = evt.target.value;
        if (evt.target.name === 'cnpj') {
            setState({
                ...state,
                [evt.target.name]: maskDocument(value),
            });
        } else {
            setState({
                ...state,
                [evt.target.name]: value,
            });
        }

    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        event.stopPropagation();

        const user = {
            name: state.name,
            cnpj: state.cnpj,
            email: state.email,
            pass: state.pass
        }
        try {
            const res = await authService.createNewUser(user);
            if (!res.error) {
                toast.success('Cadastro finalizado com sucesso!')
                const timer = setTimeout(() => {
                    window.location.href = '/login'
                }, 3000);
                return () => clearTimeout(timer);
            }
        } catch (err) {
            console.log(err)
            toast.error('Erro ao cadastrar')
            setLoading(false)
        }



    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        const requirements = [
            { label: 'Mínimo de 8 caracteres', valid: password.length >= minLength },
            { label: 'Pelo menos uma letra maiúscula', valid: hasUpperCase },
            { label: 'Pelo menos uma letra minúscula', valid: hasLowerCase },
            { label: 'Pelo menos um número', valid: hasNumber },
            { label: 'Pelo menos um caractere especial', valid: hasSpecialChar },
        ];

        return requirements;
    };

    const passwordRequirements = validatePassword(state.pass);


    return (
        <div className="">
            <Row>
                <Col md={12} lg={12} xl={12}>


                    <div className="formLogin">

                        <h2>Sign up</h2>
                        <p>We suggest using the email address you use at work.</p>

                        <Form onSubmit={handleSubmit}>
                            {/* <img alt="" src='/onework-f.png' width={150} className='logo-login' />
                            <div className="badge-info">
                                <Badge bg={'degrade'}>Você está a um passo de simplifica sua conexão com seus colaboradores</Badge>
                            </div> */}
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Onework S.A" name="name" onChange={handleChange} className='input' />
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mb-3">
                                    <Form.Label>CNPJ</Form.Label>
                                    <Form.Control maxLength={18} required type="text" value={state.cnpj} placeholder="00.000.000/0000-00" name="cnpj" onChange={handleChange} className='input' />
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom04" className="mb-3">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control required type="text" placeholder="john@onework.com" name="email" onChange={handleChange} className='input' />
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom02" className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required type={isView ? 'text' : 'password'} placeholder="******" name="pass" onChange={handleChange} className='input' />

                                    <div className="icon" onClick={() => setIsView(!isView)}>
                                        {isView ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        }
                                    </div>
                                    <div className="password-requirements mt-4">

                                        <ul className="list-unstyled">
                                            {passwordRequirements.map((req, index) => (
                                                <li key={index} className={req.valid ? "text-12 text-success" : " text-12 text-danger"}>
                                                    {req.valid ? '✅' : '❌'} {req.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Form.Group>

                                <a href="/login" className="mt-3 mb-3">Already hava a account?</a>
                            </Row>
                            <ButtonOw type="submit" loading={loading}>Register</ButtonOw>
                        </Form>
                    </div>

                </Col>
                <div className="logo-footer">
                    powered by<br />
                    <a href="https://contabilidade.com/" target="_blank">
                        <img src="/og-image.png" />
                    </a>
                </div>
            </Row>
            {/* <img alt="" src="/login-shape-1.png" className="shape2" /> */}
        </div>
    );
}