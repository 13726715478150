import { Col, Row, } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import useService from '@hooks/useService';
import ButtonOw from '../../../../components/ButtonOw';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
export default function OnboardingEmployee() {
    const { id, companyId } = JSON.parse(localStorage.getItem('user_ow'))
    const userService = useService('users');
    const [loading, setLoading] = useState(false)
    const [isView, setIsView] = useState(false);

    const [state, setState] = useState({
        name: "",
        password: "",
        passwordConfirmation: "",
        role: "EMP",
        job: "",
        company: companyId,
        currentSalary: 0,
        document: "",
        typeContract: ""
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        event.stopPropagation();

        const res = await userService.updateUser(id, state);
        if (res.data.error) {
            const error = res.data.msg.split(' ')[0];
            console.log(error)
            toast.error("Erro ao registrar dados do usuário")
            setLoading(false)
        }
        else {
            toast.success('Dados atualizados com sucesso!')
            const timer = setTimeout(() => {
                window.location.href = '/'
            }, 3000);
            setLoading(false)
        }

    };
    const handleType = (type) => {
        setState((prevState) => ({
            ...prevState,
            typeContract: type
        }));
    }

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        const requirements = [
            { label: 'Mínimo de 8 caracteres', valid: password.length >= minLength },
            { label: 'Pelo menos uma letra maiúscula', valid: hasUpperCase },
            { label: 'Pelo menos uma letra minúscula', valid: hasLowerCase },
            { label: 'Pelo menos um número', valid: hasNumber },
            { label: 'Pelo menos um caractere especial', valid: hasSpecialChar },
        ];

        return requirements;
    };

    const passwordRequirements = validatePassword(state.password);
    return (
        <div className="onboarding-e mt-3">
            <div className='mx-4 title'>
                <h2>Bem vindo!</h2>
                <p>Complete seu cadastro para utilizar a plataforma de maneira completa.</p>
            </div>

            <Col md={10}>

                <div className="formRegisterEmp">



                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Row>
                                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                                    <Form.Label>Nome completo</Form.Label>
                                    <Form.Control required type="text" placeholder="John Doe" name="name" onChange={handleChange} className='input' />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <div key={`inline-radio`} className="mb-3">
                                        <Form.Label style={{ marginRight: 20 }}>Tipo de contrato</Form.Label><br />
                                        <Form.Check
                                            inline
                                            label="CLT"
                                            name="type"
                                            type={'radio'}
                                            onClick={() => handleType('CLT')}
                                            id={`inline-radio-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="PJ"
                                            name="type"
                                            type={'radio'}
                                            onClick={() => handleType('PJ')}
                                            id={`inline-radio-2`}
                                        />

                                    </div>
                                </Col>
                                <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                                    <Form.Label>Documento (CPF ou CNPJ)</Form.Label>
                                    <InputMask
                                        mask={state.typeContract === 'PJ' ? "99.999.999/9999-99" : "999.999.999-99"}
                                        value={state.document}
                                        onChange={handleChange}
                                    >
                                        {() => <Form.Control required type="text" placeholder="000.000.000-00" name="document" onChange={handleChange} className='input' />}
                                    </InputMask>
                                </Form.Group>
                            </Row>

                            <Row>


                                <Form.Group as={Col} md="6" controlId="" className="mb-3">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Control required type="text" placeholder="Ex: Desenvolvedor" name="job" onChange={handleChange} className='input' />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="" className="mb-3">
                                    <Form.Label>Salário</Form.Label>
                                    <CurrencyInput intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                        defaultValue={0}
                                        decimalsLimit={2}
                                        placeholder="Ex: R$5.000"
                                        onValueChange={(value, name) => setState((prevState) => ({
                                            ...prevState,
                                            currentSalary: parseFloat(value)
                                        }))}
                                        className='input form-control'
                                    />
                                </Form.Group>

                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Nova senha</Form.Label>
                                    <Form.Control required type={isView ? 'text' : 'password'} placeholder="******" name="password" onChange={handleChange} className='input' />

                                    <div className="icon" onClick={() => setIsView(!isView)}>
                                        {isView ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        }
                                    </div>
                                    <div className="password-requirements mt-4">

                                        <ul className="list-unstyled">
                                            {passwordRequirements.map((req, index) => (
                                                <li key={index} className={req.valid ? "text-12 text-success" : " text-12 text-danger"}>
                                                    {req.valid ? '✅' : '❌'} {req.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom02" className="mb-3">
                                    <Form.Label>Confirmação senha</Form.Label>
                                    <Form.Control required type={'password'} placeholder="******" name="passwordConfirmation" onChange={handleChange} className='input' />
                                    {state.password !== state.passwordConfirmation && <p className='text-danger mt-2 text-start'>As senhas não conferem</p>}

                                </Form.Group>

                            </Row>
                        </Row>

                        <ButtonOw type="submit" loading={loading}>Salvar </ButtonOw>
                    </Form>
                </div>

            </Col>
        </div>
    );
}
