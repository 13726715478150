const REQUIRED_ERROR = "O campo {0} é obrigatório.";
const COMPLETE_NAME_ERROR = "Nome informado no campo {0} não esta completo.";
const EMAIL_ERROR = "O e-mail informado é inválido.";

function formatString(str, values) {
	return str.replace(/{(\d+)}/g, (match, index) => {
		return values[index];
	});
}

function isNotEmpty(value, field, msg = null) {
	if (!value || value === null || value === "" || value === undefined) {
		return {
            test : false,
            msg : msg ? msg : formatString(REQUIRED_ERROR, [field])
        };
	}
	return {
        test : true,
        msg : 'Campo válido.'
    };
}

function isValidEmail(value, field, msg = null) {
    if (!value || !value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        return {
            test : false,
            msg : msg ? msg : formatString(EMAIL_ERROR)
        };
    }
    return {
        test : true,
        msg : 'Campo válido.'
    };
}

function isCompleteName(value, field, msg = null) {
	if (!value || value.split(" ").length === 1) {
		return {
            test : false,
            msg : msg ? msg : formatString(COMPLETE_NAME_ERROR, [field])
        };
	}
	return {
        test : true,
        msg : 'Campo válido.'
    };
}

export const Validator = {
	isNotEmpty,
    isValidEmail,
	isCompleteName,
    formatString,
};
