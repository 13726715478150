import { Col, Row, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useService from "@hooks/useService";
import Loading from '@components/Loading'
import { toast } from "react-toastify";
import { formatDate } from '../../../../helpers'
export default function DeclarationsEmployee() {
    const declarationService = useService('declarations')
    const user = JSON.parse(localStorage.getItem('user_ow'))
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [paginationDataCompany, setPaginationDataCompany] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        type: 'Dúvida',
        text: '',
        identify: false,
        companyId: user.companyId
    });

    const [loading, setLoading] = useState(true);
    const [loadingSendDeclaration, setLoadingSendDeclaration] = useState(false);
    const [indexView, setIndexView] = useState(null)
    function handleChange(evt) {
        const value = evt.target.value;
        setForm({
            ...form,
            [evt.target.name]: value,
        });
    }

    const [show, setShow] = useState(false);
    const [showDeclaration, setShowDeclaration] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseDeclaration = () => setShowDeclaration(false);
    const handleShow = () => setShow(true);

    const handleShowDeclaration = (index) => {
        setIndexView(index)
        setShowDeclaration(true)
    }
    useEffect(() => {
        loadAllData();
    }, []);

    async function MyClaims() {
        const query = `myList=1&select=id|type|createdAt|identify|text|votes`
        const result = await declarationService.list(query);
        setPaginationData(result.data);
    }

    async function CompanyClaims() {
        const query = `myCompanyList=1&select=id|type|createdAt|identify|text|votes`
        const result = await declarationService.list(query);
        setPaginationDataCompany(result.data);
    }

    async function loadAllData() {
        try {
            const promises = [MyClaims(), CompanyClaims()];
            await Promise.all(promises);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    async function saveClaim() {
        try {
            await declarationService.create(form);

            loadAllData();
            setShow(false)
        } catch (err) {
            console.log(err);
            toast.error('Erro ao salvar');
        }
    }

    async function saveDeclaration() {
        try {
            setLoadingSendDeclaration(true);
            await declarationService.create(form);
            toast.success('Declaração enviada com sucesso!')
        } catch (err) {
            console.log(err);
            toast.error('Erro ao enviar declaração: ' + err.toString())
        } finally {
            setLoadingSendDeclaration(false);
        }
    }

    async function upvote(declarationId) {
        try {
            await declarationService.upvote(declarationId);
        } catch (err) {
            toast.error('Tivemos um erro ao incluir seu voto.')
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='primary' />
            </div>
        )
    }

    function nothingFoundMyList() {
        return (
            <Row>
                <Col className="text-center p-5">
                    <h4 className="mb-4">Você não possuí reclamações, deseja criar uma?</h4>
                    <ButtonOw variant="primary" onClick={handleShow}>Nova declaração</ButtonOw>
                </Col>
            </Row>
        );
    }

    function nothingFoundCompanyList() {
        return (
            <Row>
                <Col className="text-center p-5">
                    <h4 className="mb-4">Não encontramos declarações relacionadas a sua empresa, deseja criar uma?</h4>
                    <ButtonOw variant="primary" onClick={handleShow}>Nova declaração</ButtonOw>
                </Col>
            </Row>
        );
    }

    function ListAndPaginate() {
        return (
            <Row>
                <Col md="12" lg="12" className="text-end mb-3">
                    <ButtonOw variant="primary" onClick={handleShow}>Nova declaração</ButtonOw>
                </Col>

                <Col>
                    <Table hover className="rounded-table">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th style={{ width: '400px' }}>Descrição</th>
                                <th>Criação</th>
                                <th>Votos</th>
                                <th>Criado por</th>
                                <th style={{ width: '150px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginationData.list.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{item.type}</td>
                                        <td>{item.text}</td>
                                        <td>{formatDate(item.createdAt)}</td>
                                        <td><svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                        </svg> {item.votes}
                                        </td>
                                        <td>
                                            Enviado por <strong>{item.identify === true ? item.createdBy.name : 'Anônimo'}</strong>
                                        </td>
                                        <td>
                                            <ButtonOw variant='white' onClick={() => handleShowDeclaration(index)}><svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            </ButtonOw></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }

    function ListAndPaginateCompany() {
        return (
            <Row>
                <Col md="12" lg="12" className="text-end mb-3">
                    <ButtonOw variant="primary" onClick={handleShow}>Nova declaração</ButtonOw>
                </Col>

                <Col>
                    <Table hover className="rounded-table">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th style={{ width: '400px' }}>Descrição</th>
                                <th>Criação</th>
                                <th>Votos</th>
                                <th>Criado por</th>
                                <th style={{ width: '150px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginationDataCompany.list.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{item.type}</td>
                                        <td>{item.text}</td>
                                        <td>{formatDate(item.createdAt)}</td>
                                        <td onClick={() => upvote(item.id)}><svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                        </svg> {item.votes}
                                        </td>
                                        <td>
                                            Enviado por <strong>{item.identify === true ? item.createdBy.name : 'Anônimo'}</strong>
                                        </td>
                                        <td>
                                            <ButtonOw variant='white' onClick={() => handleShowDeclaration(index)}><svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            </ButtonOw></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={12}>
                    <h2>Declarações</h2>
                    <p>Faça declarações, reclamando, elogiando ou tirando dúvidas diretamente para a empresa.</p>
                </Col>

            </Row>
            <Row className="mt-4">
                <Col md={12} lg={12} className="mb-5">
                    <Badge bg='warn'>
                        <svg className="w-6 h-6 mx-2 mr-2" xmlns="http://www.w3.org/2000/svg" height={25} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                        </svg>
                        Suas declarações anônimas aparecem somente em sua lista. A empresa não é capaz de identificar o criador desses registros.
                    </Badge>
                </Col>

                {loading ? loadingPage() : <>
                    <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Minhas declarações">
                            {paginationData.totalRegisters === 0 ? nothingFoundMyList() : ListAndPaginate()}
                        </Tab>
                        <Tab eventKey="profile" title="Todas as declarações">
                            {paginationDataCompany.totalRegisters === 0 ? nothingFoundCompanyList() : ListAndPaginateCompany()}
                        </Tab>

                    </Tabs>
                    {/* {paginationData.totalRegisters === 0 ? NothingFound() : viewSheet ? ListAndPaginate() : ListColumns()} */}
                </>}
            </Row>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Faça sua reclamação com segurança</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="mx-2">Área</Form.Label>
                            <Form.Select name="area" defaultValue={'RH'} onChange={handleChange}>
                                <option value="RH">RH</option>
                                <option value="T.I">T.I</option>
                                <option value="Financeiro">Financeiro</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Customer Success">Customer Success</option>
                                <option value="Comercial">Comercial</option>
                                <option value="Governança">Governança</option>
                                <option value="Outro">Outro</option>
                            </Form.Select>
                        </Form.Group> */}
                    {/* <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                            <Form.Label className="mx-2">Categoria da reclamação</Form.Label>
                            <Form.Select name="category" defaultValue={'Técnica'} onChange={handleChange}>
                                <option value="Técnica">Técnica</option>
                                <option value="Financeira">Financeira</option>
                                <option value="Pessoal">Pessoal</option>
                                <option value="Gestão Direta">Gestão Direta</option>
                                <option value="Assédio">Assédio</option>
                                <option value="Outro">Outro</option>
                            </Form.Select>
                        </Form.Group> */}
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Label className="mx-2">Tipo da declaração</Form.Label>
                        <Form.Select name="type" defaultValue={'Dúvida'} onChange={handleChange}>
                            <option value="Dúvida">Dúvida</option>
                            <option value="Sugestão">Sugestão</option>
                            <option value="Reclamação">Reclamação</option>
                            <option value="Elogio">Elogio</option>
                            <option value="Outros">Outros</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mt-4 mb-4">
                        <Form.Label className="mx-2">Declaração</Form.Label>
                        <Form.Control type="text" as="textarea" placeholder="Descrição" name="text" className="input" onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Label className="mx-2">Assinatura</Form.Label>
                        <Form.Select name="identify" defaultValue={false} onChange={handleChange}>
                            <option value="false">Anônimo</option>
                            <option value="true">{user && user.name}</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonOw variant="primary" onClick={saveDeclaration}>
                        {loadingSendDeclaration ? 'Enviando....' : 'Enviar'}
                    </ButtonOw>
                </Modal.Footer>
            </Modal>
            {paginationDataCompany && <Modal show={showDeclaration} onHide={handleCloseDeclaration} >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-2 text-left'>

                        {indexView != null || undefined ? <>
                            <h5>{paginationDataCompany.list[indexView].type}</h5>
                            <p>{paginationDataCompany.list[indexView].text}</p>
                        </>
                            : null}

                    </div>

                </Modal.Body>
            </Modal>}

        </div >
    );
}
