import axios from 'axios';

export default class Axios {
    constructor() {
        this.axios = axios.create({
            // baseURL: 'http://147.182.235.244:3044/v1/'
            baseURL: 'https://api.onework.com.br/v1/',
            // baseURL: process.env.REACT_APP_API_ENDPOINT,
        });

        this.axios.interceptors.request.use((config) => {
            const token = localStorage.getItem('idToken_ow');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        this.axios.interceptors.response.use(
            (response) => {
                localStorage.setItem('last_page', window.location.pathname);
                return response;
            },
            (error) => {
                if (error.response) {
                    const lastPath = error.response.request.responseURL.split('/').reverse()[0];
                    if (error.response.status === 401 && lastPath !== 'me?') {
                        localStorage.removeItem('idToken_ow');
                        localStorage.removeItem('uid_ow');
                        // this.conn.logoutUser();
                        window.location.href = '/sign-in';
                    }
                    return error;
                }
            },
        );
    }

    async getServerTime() {
        return new Date();
    }

    async createUser(userData) {
        try {
            const res = this.axios.post(`auth/sign-up`, userData);
            return res;
        } catch (err) {
            console.log(err);
            return false;
        }
    }
    async addUser(userData) {
        try {
            const { data } = this.axios.post(`auth/add-user`, userData);
            return data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async logoutUser() {
        try {
            const { data } = this.axios.get(`auth/log-out`);
            return data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async loginUser(email, pass) {
        try {
            const res = await this.axios.post(`auth/sign-in`, { email, pass });
            return res.data;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async me(query) {
        try {
            const res = await this.axios.get(`auth/me?${query}`);
            return res;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async create(path, sendData) {
        try {
            const res = await this.axios.post(`${path}/`, sendData);
            return res;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async destroy(path, id) {
        try {
            const { data } = this.axios.delete(`${path}?id=${id}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async restroy(path, id) {
        try {
            const { data } = await this.axios.path(`${path}?id=${id}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async update(path, sendData, id) {
        try {
            const { data } = await this.axios.put(`${path}/${id}`, sendData);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async get(path, id) {
        try {
            const { data } = await this.axios.get(`${path}/${id}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async list(path, query, order = 'id:desc') {
        try {
            const { data } = await this.axios.get(`${path}?${query}&order=${order}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async count(path, query) {
        try {
            const { data } = await this.axios.get(`${path}/count?${query}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }

    async listAll(path, query, order = 'id:desc') {
        try {
            const { data } = await this.axios.get(`${path}/all?${query}&order=${order}`);
            return data;
        } catch (err) {
            console.log(err);
            return err.toString();
        }
    }
}
