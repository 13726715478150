import { useState, useEffect } from 'react'
import { Button, Image } from "react-bootstrap";
import ButtonOw from "../ButtonOw";
import Select, { components, MultiValueGenericProps } from 'react-select';
import useService from '@hooks/useService';
import { toast } from 'react-toastify';
import { MultiSelect } from 'primereact/multiselect';
export default function SelectEmployee({ onChange, id }) {
  const employeeService = useService("users");
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [employees, setEmployees] = useState(null)

  useEffect(() => {
  
    fetchEmployees();

  }, []);

  useEffect(() => {
    onChange(employees)
  }, [employees])
  async function fetchEmployees() {
    try {
      const result = await employeeService.list("role=EMP");
      setData(result.data.list);
      setLoading(false)
    } catch (err) {
      toast.error('Tivemos um erro ao listar os colaboradores')
    }

  }

  return (
    <div className="flex justify-content-center">
      <MultiSelect filter id={id} value={employees} onChange={(e) => setEmployees(e.value)} options={data} optionLabel="name" display="chip"
        placeholder="Selecionar colaborador" maxSelectedLabels={3} style={{ maxWidth: '100%', width: '100%' }} />
    </div>
  );
}