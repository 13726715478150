import BaseService from "./Base.Service";

class CompanyService extends BaseService {
	constructor() {
		super("companies");
	}

	async getCompanyData() {
        try {
            const { data } = await this.conn.axios.get(`/companies/company-data`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao buscar empresa", false, err);
        }
    }
}

export default CompanyService;
