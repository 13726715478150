import { Col, Row, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { Link, Outlet, useParams } from "react-router-dom";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import Loading from '@components/Loading'
import { money, maskDocument } from "../../../../helpers";
import { toast } from "react-toastify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ButtonOw from "@components/ButtonOw";

export default function Details() {
	const employeeService = useService("users");
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(true)
	const params = useParams()
	useEffect(() => {
		fetchEmploye();
	}, []);

	async function fetchEmploye() {
		try {
			const result = await employeeService.get(params.id);
			setData(result.data);
			setLoading(false)
		} catch (err) {
			console.log(err)
			setLoading(false)
		}

	}

	function alertCopy() {
		toast.info('Dado copiado!', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		});
	}

	function showReceitaFederalData() {
		if (data.companyData) {
			return (
				<>
					<Row style={{ marginBottom: '1em' }}>
						<Col>Dados retirados da receita federal em : {data?.companyData?.updatedAt}</Col>
						<Col style={{ textAlign: 'right' }}>
							<ButtonOw>Atualizar</ButtonOw>
						</Col>
					</Row>

					<div className="sitaucao-receita">
						<Row>
							<Col>
								<strong>Número de inscrição:</strong>
								<span>{data?.companyData?.cnpj}/{data?.companyData?.descricaoidentificador_matriz_filial}</span>
							</Col>
							<Col>
								<strong>Data de abertura:</strong>
								<span>{data?.companyData?.data_inicio_atividade}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Razao Social:</strong>
								<span>{data?.companyData?.razao_social}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Nome Fantasia:</strong>
								<span>{data?.companyData?.nome_fantasia}</span>
							</Col>
							<Col>
								<strong>Porte:</strong>
								<span>{data?.companyData?.porte}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Código e descrição da atividade econômica principal:</strong>
								<span>{data?.companyData?.cnae_fiscal} {data?.companyData?.cnae_fiscal_descricao}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Código e descrição da natureza jurídica:</strong>
								<span>{data?.companyData?.codigo_natureza_juridica} {data?.companyData?.natureza_juridica}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Logradouro:</strong>
								<span>{data?.companyData?.logradouro}</span>
							</Col>
							<Col>
								<strong>Número:</strong>
								<span>{data?.companyData?.numero}</span>
							</Col>
							<Col>
								<strong>Complemento:</strong>
								<span>{data?.companyData?.complemento}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>CEP:</strong>
								<span>{data?.companyData?.cep}</span>
							</Col>
							<Col>
								<strong>Bairro/Distrito:</strong>
								<span>{data?.companyData?.bairro}</span>
							</Col>
							<Col>
								<strong>Município:</strong>
								<span>{data?.companyData?.muicipio}</span>
							</Col>
							<Col>
								<strong>UF:</strong>
								<span>{data?.companyData?.uf}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Email:</strong>
								<span>{data?.companyData?.email}</span>
							</Col>
							<Col>
								<strong>Telefone:</strong>
								<span>{data?.companyData?.ddd_telefone_1}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Ente federativo responsável (EFR):</strong>
								<span>{data?.companyData?.ente_federativo_responsavel}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Situação cadastral:</strong>
								<span>{data?.companyData?.descricao_situacao_cadastral}</span>
							</Col>
							<Col>
								<strong>Data situação cadastral:</strong>
								<span>{data?.companyData?.data_situacao_cadastral}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Motivo da situação cadastral:</strong>
								<span>{data?.companyData?.descricao_motivo_situacao_cadastral}</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<strong>Situação especial:</strong>
								<span>{data?.companyData?.situacao_especial}</span>
							</Col>
							<Col>
								<strong>Data situação especial:</strong>
								<span>{data?.companyData?.data_situacao_especial}</span>
							</Col>
						</Row>
					</div>
				</>
			);
		} else {
			return (
				<>
					<Row style={{ marginBottom: '1em', textAlign: 'center' }}>
						<Col>A empresa não possui dados sobre a situação na receita, gostaria de fazer uma busca?</Col>
					</Row>
					<Row>
						<Col style={{ textAlign: 'center' }}>
							<ButtonOw>Buscar dados</ButtonOw>
						</Col>
					</Row>
				</>
			)
		}
	}

	return (
		<div className="dashboard mt-5">
			<a href="/c/employees">Voltar</a>
			{loading ? <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
				<Loading variant='secondary' />
			</div> : <Row className="mt-4  justify-content-center align-itens-center">
				<Col md={7}>
					<Card>
						<Row>
							<Col md={12}>

								{data.avatar ? <Image src={data.avatar} circle style={{ maxWidth: 150 }} /> : <svg width={145} className='mx-2' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
									<path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
								</svg>}<br />
								<h2>{data.name}</h2>
								<p>Cargo: {data.job}</p>

							</Col>
							<Col md={6}>
								<p>Salário: {money(data.currentSalary)}</p>
								<CopyToClipboard text={maskDocument(data.document)}
									onCopy={() => alertCopy()}>

									<p>Documento: {maskDocument(data.document)} <svg
										width={55}
										style={{ width: 35, cursor: "pointer" }}
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
										/>
									</svg></p>
								</CopyToClipboard>

								<p>Tipo de contato: {data.typeContract}</p>
							</Col>
							<Col md={6}>

								<p>Contato</p>
								<CopyToClipboard text={data.email}
									onCopy={() => alertCopy()}>

									<p>Email: {data.email} <svg
										width={55}
										style={{ width: 35, cursor: "pointer" }}
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
										/>
									</svg></p>
								</CopyToClipboard>

							</Col>
						</Row>

						<hr />

						{showReceitaFederalData()}
					</Card>
				</Col>
			</Row>}

		</div>
	);
}
