import { Col, Row, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Card from "@components/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import Tour from "@components/Tour";
import Image from 'react-bootstrap/Image';

import SelectEmployee from "@components/SelectEmployee";
import NotFound from "@components/NotFound";
import { toast } from "react-toastify";
import Loading from '@components/Loading'
import PaginationOw from '@components/PaginationOw'
import { formatDate } from "../../../../helpers";
export default function Onboarding() {
    const onboardingsService = useService('onboardings');
    const [loadingView, setLoadingView] = useState(false)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [title, setName] = useState('')
    const [status, setStatus] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [page, setPage] = useState(1)
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        employee: [],
        search: '',
    })
    const [viewSheet, setViewSheet] = useState(false)

    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {

        if (form.search.length > 0) {
            search()
        } else {
            GetDocuments();
        }

    }, [page]);

    async function GetDocuments() {
        setLoading(true)

        try {
            // const populates = 'companyId:name,id|sharedWith:name,id'
            const result = await onboardingsService.list(`page=${page}`);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }

    }
    async function search() {
        if (form.search.length > 0 || form.employee.length > 0) {
            setLoading(true)

            const stringData = form.employee.reduce((result, item) => {
                return `${result}${item}|`
            }, "")

            const sharedWith = stringData.slice(0, -1)
            try {
                const populates = 'companyId:name,id|sharedWith:name,id'
                const result = await onboardingsService.list('page=' + page + '&filename=' + form.search + '&sharedWith=' + sharedWith + '&populate=' + populates);
                setPaginationData(result.data)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                setPaginationData({
                    totalRegisters: 0
                })
                toast.error(err)
            }
        } else {
            GetDocuments()
        }

    }

    function selectFilterEmployee(e) {
        if (e) {
            const ev = []
            e.map((item) => {
                ev.push(item.id)
            })

            setForm((prevState) => ({
                ...prevState,
                employee: ev
            }));
        }


    }

    function changeView() {
        setLoadingView(true)
        const timer = setTimeout(() => {
            setViewSheet(!viewSheet)
            setLoadingView(false)
        }, 1500);
        return () => clearTimeout(timer);

    }
    function handleChange(e) {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    async function createOnboarding() {
        setLoadingButton(true)
        const res = await onboardingsService.create({ title, status })
        if (res.data.id) {
            window.location.href = '/c/onboarding/' + res.data.id
        } else {
            toast.error('Tivemos um erro tente novamente mais tarde')
            setLoadingButton(false)
        }

    }
    function NothingFound() {
        return (
            <div>
                <NotFound action={() => handleShow()} text={'Não encontramos nenhum onboarding, deseja criar um?'} actionLabel={'Criar Onboarding'} />
            </div>
        );
    }

    function destroy(){
        //Criar função de deletar
    }
    function ListAndPaginate() {
        return (
            <Table hover className="rounded-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Colaborador</th>
                        <th>Data de upload</th>
                        <th style={{ width: '250px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.filename}</td>
                                <td>Vitor Christoval</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td>
                                    <Col className="action-buttons">
                                        <OverlayTrigger placement="top" overlay={tooltip("Editar")}>
                                            <a href="#" onClick={() => window.location.href = '/c/onboarding/' + item.id}>
                                                <svg width={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={tooltip("Visualizar")}>
                                            <a href="#" onClick={() => window.open('https://app.onework.com.br/onboarding/view/' + item.id)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={tooltip("Deletar")}>
                                        <a href="#" className="delete" onClick={() => destroy(item.id)}>
											<svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
												<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
											</svg>

										</a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        );
    }
    function ListColumns() {
        return (paginationData.list.map((item, index) => {
            return (
                <Col md={2} lg={2} xl={2} className='mb-4'>
                    <Card actions={[{
                        label: 'Editar',
                        action: () => window.location.href = '/c/onboarding/' + item.id
                    }, {
                        label: 'Visualizar',
                        action: () => window.open('https://app.onework.com.br/onboarding/view/' + item.id)
                    }]}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>

                        <div className="card-bottom">
                            <Row>
                                <Col>
                                    <h6>{item.filename}</h6>
                                    <p>Upload at:{formatDate(item.updatedAt)}</p>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>)
        })
        )
    }

    return (
        <div className="dashboard mt-5">
            <>
                {/* <Tour initialRun={true} type={'ONBOARDING'} /> */}
                <Row>
                    <Col md={10}>
                        <h2>Onboarding</h2>
                        <p>Visualize a organize melhor os onboardings para seus colaboradores.</p>
                    </Col>
                    <Col md={2} className=''>
                        <ButtonOw variant="primary" onClick={() => handleShow()} type={'button'}><svg width={18} style={{ marginRight: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                            Criar</ButtonOw>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">

                            <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" defaultValue={form.search} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <ButtonOw type={'button'} variant={'secondary'} onClick={search}>Filtrar</ButtonOw>
                    </Col>
                    <Col md={2}>
                        {!viewSheet ?
                            <OverlayTrigger placement="left" overlay={tooltip("Visualizar como Tabela")}>
                                <div><ButtonOw type={'button'} variant={'white'} onClick={changeView}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                                </svg>
                                </ButtonOw>
                                </div>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger placement="left" overlay={tooltip("Visualizar como Card")}>
                                <div><ButtonOw type={'button'} variant={'white'} onClick={changeView}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                                </svg>
                                </ButtonOw>
                                </div>
                            </OverlayTrigger>
                        }
                    </Col>
                </Row >

                <Row className="mt-4">
                    {loadingView || loading ? <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                        <Loading variant='secondary' />
                    </div> : paginationData && paginationData.totalRegisters === 0 ? NothingFound() : viewSheet ? ListAndPaginate() : ListColumns()}
                </Row>
                <Row>
                    <Col md={12} className='d-flex justify-content-center align-itens-center'>
                        <PaginationOw totalPages={paginationData.totalPages ? paginationData.totalPages : 0} currentPage={page} onClick={setPage} />
                    </Col>
                </Row></>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Novo onboarding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Label className="mx-2">Nome</Form.Label>
                        <Form.Control type="text" placeholder="Pesquisar" name="name" className="input" defaultValue={title} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <ButtonOw loading={loadingButton} variant='secondary' onClick={() => createOnboarding()}> Criar</ButtonOw>
                </Modal.Body>
            </Modal>
        </div >
    );
}
