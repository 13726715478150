export const home = [
  {
    content: (<div>
      <img alt="Bem-vindo" src="/hi-five.png" className="" width={300} />
      <h2>Vamos começar sua jornada!</h2>
      <p>Nesse tour vamos te mostrar passo a passo sobre nossa plataforma</p> </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    placement: 'center',
    target: 'body',
  },

  {
    content: (<div>
      <h3>Informações primárias</h3>
      <p>Nessa sessão você consegue visualizar alguns dados primários que acreditamos ser relevantes para seu dia-a-dia.</p>
    </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    target: '.painel',
  },

  {
    content: (<div><h3>Navegação</h3>
      <p>Navegue entre os menus para visualizar Contratos, Colaboradores, NFes, Questionários, Documentos e Onboardings</p></div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    placement: 'right',
    target: '.menu1',
  },


];
export const onboarding = [
  {
    content: (<div>
      <img alt="Bem-vindo" src="/onboarding.png" className="" width={300} />
      <h2>Crie seu primeiro onboarding</h2>
      <p>O onboarding serve para iniciar novos colaboradores, informar questões imporantes e ensinar o bom convivio dentro da empresa.</p> </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    placement: 'center',
    target: 'body',
  },
  {
    content: (<div>
      <h3>Novo onboarding</h3>
      <p>Aqui é o caminho para que você possa criar novos onboardings</p>

    </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    target: '.btn-primary',
  },

];
export const onboardingCreate = [
  {
    content: (<div>
      <img alt="Bem-vindo" src="/onboarding.png" className="" width={300} />
      <h2>Vamos começar!</h2>
      <p>Crie a primeira etapa de seu novo onboarding.</p> </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    placement: 'center',
    target: 'body',
  },
  {
    content: (<div>
      <h3>Novo etapa</h3>
      <p>Seu onboarding pode conter etapas de diferentes formatos como: Imagem, Vídeo e Texto.</p>
      <p>Você pode arrastar suas etapas para criar uma ordenação melhor.</p>
    </div>),
    spotlightClicks: true,

    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    target: '.btn-primary',
  },

];


export const nfe = [
  {
    content: (<div>
      <img alt="Bem-vindo" src="/nfes-bro.png" className="" width={300} />
      <h2>Controle suas NFes!</h2>
      <p>Centralize essas inforamações e filtre da melhor maneira possíve;.</p> </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    placement: 'center',
    target: 'body',
  },
  {
    content: (<div>
      <h3>Saiba o status atual</h3>
      <p>Seja sinalizado caso algum colaborador tenha esquecido sua Nota fiscal.</p>
      
    </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    target: '.badge',
  },
  {
    content: (<div>
      <h3>Visualize como preferir</h3>
      <p>Encontre a maneira mais útil para visualizar essas informações</p>
      
    </div>),
    locale: { skip: <small aria-label="skip">Pular tour</small>, next: <strong>Próximo</strong>, back: <small>Voltar</small>, finish: 'Finalizar' },
    target: '.btn-white',
  },

];
export const collection = [
  {
    target: '#collection-cont',
    showProgress: false,
    disableBeacon: true,
    showProgress: false,
    placement: 'top-center',
    title: 'Etapa Concluida',
    content:
      'Agora você aprendeu a como gerir os seus precedentes e otimizar o trabalho de toda a equipe.',
    moduleTour: 'collection',
  },
];

export const monitoring = [
  {
    target: '#menu-monitoring',
    disableBeacon: true,
    placement: 'right',
    title: 'Monitoramento jurisprudencial',
    content:
      'Aqui você pode criar, editar e acompanhar os seus monitoramentos de jurisprudência. ',
  },
  {
    target: '#my-monitoring',
    disableBeacon: true,
    placement: 'right',
    title: 'Organização das pastas',
    content:
      'Os monitoramentos estão organizados entre criados por você, compartilhados com pessoas específicas e compartilhados com todo o seu escritório/empresa.',
  },
  {
    target: '.create-new-monitoring-btn',
    disableBeacon: true,
    placement: 'right',
    title: 'Criando um monitoramento',
    content:
      'Ao criar um novo monitoramento, você verá todas as opções de configuração disponíveis. Além das palavras monitoradas, você pode adicionar qualquer um dos nossos filtros de pesquisa, bem como a frequência de notificação e por onde deseja recebê-la.',
  },
  {
    target: '#menu-monitoring',
    disableBeacon: true,
    placement: 'right',
    title: 'Etapa Concluida ',
    content:
      'Agora fique tranquilo, você não precisa realizar novas pesquisas envolvendo estes termos. Nosso robô pesquisará por você!',
    moduleTour: 'monitoring',
  },
];
