import { Button } from 'react-bootstrap';
export default function ButtonOw({ type, variant = 'primary', loading, onClick, children, style, disabled }) {
    return (
        <Button
            disabled={disabled}
            type={type}
            variant={variant}
            onClick={onClick}
            style={style}>
            {loading ? (
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            ) : (
                children
            )}
        </Button>
    );
}
