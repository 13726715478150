
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import ButtonOw from "@components/ButtonOw";
import NotFound from "@components/NotFound";
import Modal from 'react-bootstrap/Modal';
import Loading from '@components/Loading'
import { toast } from "react-toastify";

export default function Warnings() {
    const alerts = useService('alerts');
    const [deleteId, setDeleteId] = useState()
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [form, setForm] = useState({
        employee: [],
        search: '',
    })
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };

    useEffect(() => {
        fetchAlerts();
    }, []);


    async function destroy(id) {
        setShow(false)
        try {
            const res = await alerts.destroy(id)
            const timer = setTimeout(() => {
                window.location.href = '/c/alerts'
            }, 1000);
        } catch (err) {
            toast.error(err)

        }
    }
    function showModalDelete(id) {
        setDeleteId(id)
        handleShow()
    }

    async function fetchAlerts() {
        try {
            setLoading(true);
            // const query = `select=id|text|createdAt|createdBy|imageURL`
            const result = await alerts.list();
            setPaginationData(result.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }

    function NothingFound() {
        return (
            <div>
                <div className="notFound">
                    <h4>Não encontramos nenhum dado até o momento</h4>
                    <ButtonOw type={'button'} variant={'secondary'} onClick={() => window.location.href = 'alerts/new'}>
                        Criar comunicado
                    </ButtonOw>
                    <br />
                    <img alt="No Data" src="/no-com.jpeg" className="blend-img" width={400} />
                </div>
            </div>
        );
    }

    function ListAndPaginate() {
        return (
            <Table hover className="rounded-table">
                <thead>
                    <tr>
                        <th width="100px"></th>
                        <th>Texto</th>
                        <th style={{ width: '400px' }}>Compartilhado com</th>
                        <th style={{ width: '150px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, index) => {
                        return (
                            <tr>
                                <td><img alt={item.text} src={item.imageURL} style={{ width: '180px' }} /></td>
                                <td className="pt-3">{item.text}</td>
                                <td className="pt-3">{item.shareType == 'all' ? 'Todos' : item.shareType}</td>

                                <td className="pt-4">
                                    <Col className="action-buttons">
                                        {/* <OverlayTrigger placement="top" overlay={tooltip("Visualizar")}>
                                            <a href="#" onClick={() => alert(item.filename)}>
                                                <svg height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger> */}
                                        <OverlayTrigger placement="top" overlay={tooltip("Deletar")}>
                                            <a href="#" className="delete" onClick={() => showModalDelete(item.id)}>
                                                <svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>

            </Table>
        );
    }

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={6}>
                    <h2>Comunicados</h2>
                    <p>Comunique-se melhor com todos seus colaboradores.</p>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                        <Form.Control type="text" placeholder="Pesquisar" name="search" className="input" />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <ButtonOw type={'button'} variant={'secondary'} >Filtrar</ButtonOw>
                </Col>

                <Col md={6} style={{ textAlign: 'right' }}>
                    <ButtonOw type={'button'} variant={'primary'} onClick={() => window.location.href = 'alerts/new'}>Criar comunicado</ButtonOw>
                </Col>
            </Row >

            <Row className="mt-4">
                {loading ? loadingPage() : paginationData.totalRegisters === 0 ? NothingFound() : ListAndPaginate()}
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center mb-4">
                    <img src="/delete1.svg" width={300} />
                    <h5 className="mb-4 mt-3">Você tem certeza que <br />deseja deletar esse comunicado?</h5>
                    <ButtonOw variant="danger" onClick={() => destroy(deleteId)}>
                        Confirmar
                    </ButtonOw>
                </Modal.Body>

            </Modal>
        </div >
    );
}
