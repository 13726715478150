import BaseService from "./Base.Service";

class ProjectUsersService extends BaseService {
	constructor() {
		const model = {
			name: { type: String, required: true },
			users: { type: Array, required: true }
		};

		super("project-users", model);
	}

    async delete(projectId, userId) {
        try {
            const { data } = await this.conn.axios.delete(`/project-users/${projectId}/user/${userId}`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
    async addUser(projectId, users) {
        try {
            const { data } = await this.conn.axios.post(`/project-users/${projectId}/`, users);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
}

export default ProjectUsersService;
