import React, { Component } from "react";
import { hexToRgb, rgbToHex, interpolateColors } from '../../helpers'
import Chart from "react-apexcharts";


export default function DeclarationGraph({ title = '', series = [], labels = [], primary = '#D1F593', secondary = '#000229', steps = 4 }) {
    let colorSteps = interpolateColors(hexToRgb(primary), hexToRgb(secondary), steps);

    if (colorSteps) {
        colorSteps = colorSteps.map(e => {
            return rgbToHex(e[0], e[1], e[2]);
        })
    }

    

    const state = {
        series: series,
        options: {
            fill: {
                colors: colorSteps
            },
            chart: {
                width: '100%',
                type: 'pie',
            },
            labels: labels,
            theme: {
                monochrome: {
                    enabled: true
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -5
                    }
                }
            },
            title: {
                text: title
            },
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, val.toFixed(1) + '%']
                }
            },
            legend: {
                show: false
            }
        },
    };

    function nothingFound() {
        return (
            <>
                <div>
                    <h5>{title}</h5>
                </div>
                <div className='img-nothing-found-placeholder blend-img'>
                    <img alt="Nada foi encontrado" src="/no-data-7.jpeg" width='100%' />
                </div>
            </>
        );
    }

    if (series.length > 0) {
        return (
            <div className="mixed-chart">
                <Chart
                    options={state.options}
                    series={state.series}
                    type="pie"
                    height="300"
                />
            </div>
    
        );
    } else {
        return nothingFound();
    }
}