import { useEffect, useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import Card from '@components/Card';
import ButtonOw from '@components/ButtonOw';
import Clock from '@components/Clock';
import useService from '@hooks/useService';
import Loading from '@components/Loading';
import { toast } from 'react-toastify';

export default function Dashboard() {
    // const employeeService = useService('users');
    const documentsService = useService('documents');
    const taxInvoicesService = useService('taxInvoices');
    const claimsService = useService('claims');
    const alertsService = useService('alerts');
    const userService = useService('users');
    const declarationService = useService('declarations');

    const user = JSON.parse(localStorage.getItem('user_ow'));
    const [totalDocs, setTotalDocs] = useState(0);
    const [loadingDocs, setLoadingDocs] = useState(true);
    const [totalTaxInvoices, setTotalTaxInvoices] = useState(0);
    const [loadingTaxInvoices, setLoadingTaxInvoices] = useState(true);
    const [totalClaims, setTotalClaims] = useState(0);
    const [loadingClaims, setLoadingClaims] = useState(true);
    const [verifyAlerts, setVerifyAlerts] = useState(null);
    // const [totalQuiz, setTotalQuiz] = useState(0);
    // const [loadingQuiz, setLoadingQuiz] = useState(true);
    const [loadingSendDeclaration, setLoadingSendDeclaration] = useState(false);
    const [alertIndex, setAlertIndex] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getTotalDocs();
        getTotalClaims();
        getUserAlerts();
        getTotalTaxInvoices();
        getDashboardEmp();
    }, []);

    const [formDeclaration, setFormDeclaration] = useState({
        type: 'Dúvida',
        text: '',
        identify: false,
        companyId: user ? user.companyId : null,
    });

    function handleChangeDeclaration(evt) {
        const value = evt.target.value;
        setFormDeclaration({
            ...formDeclaration,
            [evt.target.name]: value,
        });
    }

    async function getTotalDocs() {
        const result = await documentsService.count(`sharedUsers=${user.id}`);
        setTotalDocs(result.data.count ? result.data.count : 0);
        setLoadingDocs(false);
    }

    async function getTotalTaxInvoices() {
        const result = await taxInvoicesService.count(`userId=${user.id}`);
        setTotalTaxInvoices(result.data.count ? result.data.count : 0);
        setLoadingTaxInvoices(false);
    }

    async function getTotalClaims() {
        const result = await claimsService.count(`createdBy=${user.id}`);
        setTotalClaims(result.data.count ? result.data.count : 0);
        setLoadingClaims(false);
    }

    async function getUserAlerts() {
        try {
            const result = await alertsService.getListEmpDash();
            if(result.data){
                setAlerts(result.data);
            }
            
        }catch(err){
            console.log(err)
        }
       
    }

    async function getDashboardEmp() {
        const result = await userService.getDashboardEmployee();
        setVerifyAlerts(result.data);
    }

    async function saveDeclaration() {
        try {
            setLoadingSendDeclaration(true);
            await declarationService.create(formDeclaration);
            toast.success('Declaração enviada com sucesso!');
        } catch (err) {
            console.log(err);
            toast.error('Erro ao enviar declaração: ' + err.toString());
        } finally {
            setLoadingSendDeclaration(false);
        }
    }

    function viewAlert(i) {
        setAlertIndex(i);
        handleShow();
    }

    function zeroAlert() {
        return (
            <>
                <div
                    className="line-item"
                    style={{ backgroundColor: '#dddddd', padding: '10px' }}>
                    <div>
                        <p>Sem comunicados no momento!</p>
                    </div>
                </div>
            </>
        );
    }

    function listAlerts() {
        return (
            <>
                {alerts.map((alert, i) => (
                    <div className="line-item">
                        <Row onDoubleClick={() => viewAlert(i)}>
                            <Col>
                                <h6>{alert.text}</h6>
                            </Col>
                        </Row>

                        <div>
                            <a
                                href="#"
                                onClick={() => viewAlert(i)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col
                    md={4}
                    sm={12}
                    xs={12}
                    className="mb-3">
                    <Card>
                        <div className="card-colaboradores">
                            {loadingDocs ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/e/documents', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>

                                    <h2>{totalDocs}</h2>
                                    <p>Documentos</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col
                    md={4}
                    sm={12}
                    xs={12}
                    className="mb-3">
                    <Card>
                        <div className="card-questionarios">
                            {loadingTaxInvoices ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/e/nfes', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>
                                    <h2>{totalTaxInvoices}</h2>
                                    <p>Notas emitidas</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col
                    md={4}
                    sm={12}
                    xs={12}
                    className="mb-3">
                    <Card>
                        <div className="card-questionarios">
                            {loadingClaims ? (
                                <Loading variant="secondary" />
                            ) : (
                                <>
                                    <div className="float-button-card">
                                        <ButtonOw
                                            variant="link"
                                            onClick={() => window.open('/e/declarations', '_self')}>
                                            Ver
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                                height={15}>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                                                />
                                            </svg>
                                        </ButtonOw>
                                    </div>
                                    <h2>{totalClaims}</h2>
                                    <p>Reclamações feitas</p>
                                </>
                            )}
                        </div>
                    </Card>
                </Col>

                {/* <Col>
                    <Card >
                        <div className='card-satisfacao'>
                            <h2>
                                0
                            </h2>
                            <p>Questionários pendentes</p>
                        </div>

                    </Card>
                </Col> */}
            </Row>
            <Row className="mt-4">
                <Col>
                    <Clock></Clock>
                </Col>
            </Row>

            <Row style={{ marginTop: '1rem' }}>
                <Col
                    md={6}
                    sm={12}
                    xs={12}
                    className="mb-3">
                    <Card>
                        <div className="pendencies">
                            <h4>
                                {' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                    />
                                </svg>
                                Pendências
                            </h4>

                            <div className="line-item">
                                <Row>
                                    <Col md={12}>
                                        {verifyAlerts && verifyAlerts.answerQuiz ? (
                                            <></>
                                        ) : (
                                            <div className="cardPendency">
                                                <p>Você possui questionário(s) sem responder.</p>
                                                <ButtonOw
                                                    onClick={() => (window.location.href = '/e/quiz')}
                                                    variant="primary">
                                                    Resolver
                                                </ButtonOw>
                                            </div>
                                        )}

                                        {verifyAlerts && verifyAlerts.sendTaxInvoice ? (
                                            <></>
                                        ) : (
                                            <div className="cardPendency">
                                                <p>Você possui pendências em suas notas fiscais.</p>
                                                <ButtonOw
                                                    onClick={() => (window.location.href = '/e/nfes')}
                                                    variant="primary">
                                                    Resolver
                                                </ButtonOw>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Card>
                </Col>
                {/* <Col md={4}>
                    <Card>
                        <div className='duvidas'>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                            </svg>
                                Suporte</h4>
                            <small>Dúvidas, sugestões ou reclamações.</small>
                            <div className="form">

                                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3" onChange={handleChangeDeclaration}>
                                    <Form.Label className="mx-2">Categoria</Form.Label>
                                    <Form.Select name="type" defaultValue={'Dúvida'} >
                                        <option value="Dúvida">Dúvida</option>
                                        <option value="Sugestão">Sugestão</option>
                                        <option value="Reclamação">Reclamação</option>
                                        <option value="Elogio">Elogio</option>
                                        <option value="Outro">Outro</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mt-4 mb-4" onChange={handleChangeDeclaration}>
                                    <Form.Label className="mx-2">Comentário</Form.Label>
                                    <Form.Control type="text" as="textarea" placeholder="Descrição" name="text" className="input" />
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3" onChange={handleChangeDeclaration}>
                                    <Form.Label className="mx-2">Assinatura</Form.Label>
                                    <Form.Select name="identify" defaultValue={false}>
                                        <option value="false">Anônimo</option>
                                        <option value="true">{user && user.name}</option>
                                    </Form.Select>
                                </Form.Group>
                                <ButtonOw variant="primary" onClick={saveDeclaration}>
                                    {loadingSendDeclaration ? 'Enviando...' : 'Enviar' }
                                </ButtonOw>
                            </div>

                        </div>
                    </Card>
                </Col> */}
                <Col
                    md={6}
                    sm={12}
                    xs={12}
                    className="mb-3">
                    <Card>
                        <div className="comunicados">
                            <h4>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                                    />
                                </svg>
                                Comunicados
                            </h4>

                            {alerts.length === 0 ? zeroAlert() : listAlerts()}
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Comunicado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4 text-center">
                        {alertIndex != null || undefined ? (
                            <img
                                width={'80%'}
                                className="mb-3"
                                src={alerts[alertIndex].imageURL}
                            />
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                                />
                            </svg>
                        )}
                        <br />
                        {(alerts.length > 0 && alertIndex != null) || undefined ? alerts[alertIndex].text : null}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
