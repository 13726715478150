import BaseService from "./Base.Service";

class OnboardingService extends BaseService {
	constructor() {
		const model = {
			title: { type: String, required: true },
			status: { type: Boolean, default: false },
		};

		super("onboardings", model);
	}
	async addStep(body) {
		
		try {
			const { data } = await this.conn.axios.post(`/onboardings/add-step`, body);
			return data;
		} catch (err) {
			return this.sendError("Erros foram encontrados ao salvar", false, err);
		}
	}
}

export default OnboardingService;
