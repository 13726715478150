
import { Container, Row, Col } from "react-bootstrap"

export default function OverlayOw() {
    return (
        <div className="overlay-ow">
            <div>
                <img src="/ow.png" alt="Onework" /><br />
            </div>
            <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
    )
}