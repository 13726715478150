import BaseService from "./Base.Service";

class DeclarationsService extends BaseService {
	constructor() {
		super("declarations");
	}

    async upvote(declarationId) {
        try {
            const { data } = await this.conn.axios.post(`/declarations/upvote`, { declarationId });
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao salvar", false, err);
        }
    }

    async getCompanyGraphData() {
        try {
            const {data} = await this.conn.axios.get(`/declarations/company-graph`);
            return data;
        } catch (err) {
            console.log(err);
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
}

export default DeclarationsService;
