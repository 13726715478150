import BaseService from "./Base.Service";

class ModulesService extends BaseService {
	constructor() {
		const model = {
			module: { type: String, required: true },
			value: { type: Number, required: true },
			status: { type: String, required: true },
		};

		super("modules", model);
	}
}

export default ModulesService;
