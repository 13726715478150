import { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
export default function SelectType({ setType }) {
	const [selected, setSelected] = useState(0)

	function select(value) {
		switch (value) {
			case 'text':
				setType('contentType', 'text')
				setSelected(1)
				break;
			case 'video':
				setType('contentType', 'video')
				setSelected(2)
				break;
			case 'image':
				setType('contentType', 'image')
				setSelected(3)
				break;

		}

	}
	return (
		<Row className="newType">
			<h5>Seleciona o formato da etapa</h5>
			<Col>
				<div className={selected == 1 ? 'typeOption activeStep' : 'typeOption'} onClick={() => select('text')}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
					</svg>
					<p>
						Texto
					</p>
				</div>
			</Col>
			<Col>
				<div  className={selected == 2 ? 'typeOption activeStep' : 'typeOption'} onClick={() => select('video')}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
					</svg>
					<p>
						Vídeo
					</p>
				</div>
			</Col>
			<Col>
				<div  className={selected == 3 ? 'typeOption activeStep' : 'typeOption'} onClick={() => select('image')}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
					</svg>
					<p>
						Imagem
					</p>
				</div>
			</Col>
		</Row>

	);
}
