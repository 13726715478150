import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";
import SelectEmployee from "@components/SelectEmployee";
import ButtonOw from "../../../../components/ButtonOw";
import { toast } from "react-toastify";
export default function NewProject() {
	const projects = useService('projects');

	const [loadingCreate, setLoadingCreate] = useState(false)
	const [name, setName] = useState('')
	//Select para compartilhar com usuário utilizar em atualização
	const [employe, setEmploye] = useState(null)
	const selectShareEmployee = (e) => {
		if (e) {
			const ev = []
			e.map((item) => {
				ev.push(item.id)
			})
			setEmploye(ev);
		}
	}

	async function create(event) {
		setLoadingCreate(true)
		event.preventDefault();
		event.stopPropagation();
	
		const formData = {
			name: name,
			users: employe
		}
		try {
			const res = await projects.create(formData)
			console.log(res)
			if(res){
				toast.success('Projeto criado com sucesso')
				setLoadingCreate(false)
			}else{
				toast.error('Erro ao criar projeto')
				setLoadingCreate(false)
			}
		
		
		} catch (err) {
			toast.error(err)
		}

	}
	return (
		<div className="dashboard mt-5">
			<Row>
				<Col md={10}>
					<a href="/c/projects">Voltar</a>
					<h2 className="mt-3">Novo Projeto</h2>
					<p>Crie projetos e associe seus colaboradores para o apontamento de horas.</p>
				</Col>


			</Row>

			<Row>
				<Col md={6} xs={12} sm={12}>
					<Form onSubmit={create}>
						<Form.Group controlId="validationCustom01" className="mb-3">
							<Form.Label className="mx-2">Nome do Projeto</Form.Label>
							{/* <Form.Control type="text" rows={3} as="textarea"  placeholder="ex: Zeus" name="text" className="input"  /> */}
							<Form.Control type="text" placeholder="ex: Zeus" name="text" className="input"  onChange={(e) => setName(e.target.value)}/>
						</Form.Group>
						<Form.Group controlId="validationCustom02" className="mb-4">
							<Form.Label className="mx-2">Selecione o(s) colaborador(es)</Form.Label>
							<SelectEmployee className={'mt-4'} name="employee1" onChange={selectShareEmployee} />
						</Form.Group>
						<ButtonOw type={'submit'} variant='primary' className='mt-4' loading={loadingCreate}>Criar Projeto</ButtonOw>
					</Form>

				</Col>
				<Col md={4} className='mx-5'>
					<img alt="Comunicados" src="/no-com.jpeg" className="blend-img" width={400} />

				</Col>
			</Row>

		</div>
	);
}
