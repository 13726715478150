import { Col, Row, Tabs, Tab, Badge } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import useService from '@hooks/useService';
import InputMask from 'react-input-mask';
import ButtonOw from '../../../../components/ButtonOw';

import { toast } from 'react-toastify';
export default function Config() {
    const companyService = useService('company');
    const toolsService = useService('tools');

    const [formData, setFormData] = useState({});
    const [formNormalLogo, setNormalLogo] = useState(null);
    const [formSmallLogo, setSmallLogo] = useState(null);
    const [formColors, setFormColors] = useState({});
    const [formParameters, setFormParameters] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    const textForPeriod = {
        'every_day': "Esse tipo de emissão considera o envio todo o dia especificado. Ex.: Envio da nota todo dia 15, sendo 15 o parâmetro a ser especificado.",
        'every_week_day': 'Esse tipo de emissão considera o envio em um dia da semana específico. Ex.: Envio toda terça-feira.',
        'every_last_day_on_month': 'Esse tipo de emissão considera o ultimo dia do mês, sendo 28, 29, 30 ou 31.',
        'every_first_day_on_month': 'Esse tipo de emissão considera a emissão sempre para o primeiro dia do mês.',
        'every_days': 'Esse tipo de emissão considera o envio da nota em um período de dias. Ex.: Envio de 15 em 15 dias.',
        'in_date': 'Esse tipo de emissão considera o envio na data exata a ser especificada.',
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setFormData((prevState) => ({
            ...prevState,
            [evt.target.name]: value,
        }));
    }

    function handleChangeParameters(evt) {
        const value = evt.target.value;
        setFormParameters((prevState) => ({
            ...prevState,
            [evt.target.name]: value,
        }));
    }

    function handleChangeColors(evt) {
        const value = evt.target.value;
        setFormColors((prevState) => ({
            ...prevState,
            [evt.target.name]: value,
        }));
    }

    function handleNormalLogo(evt) {
        const file = evt.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setNormalLogo(reader.result);
        };
        reader.readAsDataURL(file);
    }

    function handleSmallLogo(evt) {
        const file = evt.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSmallLogo(reader.result);
        };
        reader.readAsDataURL(file);
    }

    async function searchForCep() {
        try {
            const data = await toolsService.searchForCep(formData.zipCode);
            const addressData = {
                address: data.logradouro,
                state: data.uf,
                city: data.localidade,
                location: data.bairro,
            };

            setFormData({ ...formData, ...addressData });
        } catch (err) {
            console.log(err);
        }
    }

    async function updateCompanyData(e) {
        setLoadingButton(true);
        e.preventDefault();
        try {
            const id = formData.id;


            formData.parameters = formParameters;
            formData.colors = formColors;

            if (formNormalLogo && !formNormalLogo.includes('https')) {
                formData.normalLogoData = formNormalLogo;
            }
            if (formSmallLogo && !formSmallLogo.includes('https')) {
                formData.smallLogoData = formSmallLogo;
            }

            const data = await companyService.update(formData, id);
            if (data != null && data != undefined) {
                toast.success('Configurações salvas com sucesso');
            } else {
                toast.error('Parece que tivemos alguns problema com essa requisição, tente novamente ou solicite suporte.');
            }
            getCompanyData();
        } catch (err) {
            console.log(err);
            toast.error('Parece que tivemos alguns problema com essa requisição, tente novamente ou solicite suporte.');
        }
    }

    async function getCompanyData() {
        try {
            const { data, error, msg } = await companyService.getCompanyData();
            if (error) {
                throw new Error(msg);
            } else {
                setFormData({
                    id: data.id,
                    cnpj: data.cnpj,
                    name: data.name,
                    status: data.status,
                    phone: data.phone || null,
                    headquarter: data.headquarter || null,
                    zipCode: data.zipCode || null,
                    city: data.city || null,
                    address: data.address || null,
                    state: data.state || null,
                    number: data.number || null,
                    location: data.location || null,
                    logos: data.logos,
                });

                setFormColors(data.colors || {});
                const defaultParams = {
                    defaultTimeToSendTaxInvoice: 'every_day',
                };
                setFormParameters(data.parameters || defaultParams);

                if (data.logos) {
                    if (data.logos.smallLogo) {
                        const logoData = `https://api.onework.com.br/public/${data.logos.smallLogo}`
                        setSmallLogo(logoData);
                    }
                    if (data.logos.normalLogo) {
                        const logoData = `https://api.onework.com.br/public/${data.logos.normalLogo}`;
                        setNormalLogo(logoData);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setLoadingButton(false);
        }
    }

    useEffect(() => {
        getCompanyData();
    }, []);

    function dataTab() {
        return (
            <>
                <Row>
                    <Col
                        md={10}
                        xs={12}
                        sm={12}>
                        <Form onSubmit={updateCompanyData}>
                            <Form.Group
                                controlId="validationCustom01"
                                className="mt-4 mb-4">
                                <Form.Label className="mx-2">Nome da Empresa</Form.Label>
                                <Form.Control
                                    value={formData.name}
                                    type="text"
                                    placeholder="ex: Onework LTDA"
                                    name="name"
                                    onChange={handleChange}
                                    className="input"
                                />
                            </Form.Group>

                            <Row>
                                <Col
                                    md={4}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">CNPJ</Form.Label>
                                        <InputMask
                                            mask="99.999.999/9999-99"
                                            value={formData.cnpj}
                                            onChange={handleChange}
                                        >
                                            {() => <Form.Control type="text" placeholder="00.000.000/0000-00" name="cnpj" className="input" />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={4}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Telefone</Form.Label>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            value={formData.telefone}
                                            onChange={handleChange}
                                        >
                                            {() => <Form.Control type="text" placeholder="(00) 00000-0000" name="telefone" className="input" />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={4}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Matriz</Form.Label>
                                        <Form.Control as="select">
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    md={4}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">CEP</Form.Label>
                                        <InputMask
                                            mask="99.999-999"
                                            value={formData.zipCode}
                                            onChange={handleChange}
                                            onBlur={searchForCep}
                                        >
                                            {() => <Form.Control
                                                value={formData.zipCode}
                                                type="text"
                                                placeholder="00.000-000"
                                                name="zipCode"
                                                className="input"
                                                onChange={handleChange}
                                                
                                            />}
                                        </InputMask>
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={8}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Endereço</Form.Label>
                                        <Form.Control
                                            value={formData.address}
                                            type="text"
                                            placeholder="Ex.: Rua das flores"
                                            name="address"
                                            onChange={handleChange}
                                            className="input"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    md={3}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Número</Form.Label>
                                        <Form.Control
                                            value={formData.number}
                                            type="number"
                                            placeholder="Ex.: Rua das flores"
                                            name="number"
                                            onChange={handleChange}
                                            className="input"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={3}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Bairro</Form.Label>
                                        <Form.Control
                                            value={formData.location}
                                            type="text"
                                            placeholder="Ex.: Centro"
                                            name="location"
                                            onChange={handleChange}
                                            className="input"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={3}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Cidade</Form.Label>
                                        <Form.Control
                                            value={formData.city}
                                            type="text"
                                            placeholder="Ex.: Curitiba"
                                            name="city"
                                            onChange={handleChange}
                                            className="input"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={3}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Estado</Form.Label>
                                        <Form.Control
                                            value={formData.state}
                                            type="text"
                                            placeholder="Ex.: Paraná"
                                            name="state"
                                            onChange={handleChange}
                                            className="input"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <ButtonOw
                                type={'submit'}
                                variant="primary"
                                loading={loadingButton}>
                                Salvar Configurações
                            </ButtonOw>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    function colorsTab() {
        return (
            <>
                <Row className="mt-4">
                    <Col
                        md={10}
                        sm={12}
                        xs={12}>
                        <Form onSubmit={updateCompanyData}>
                            <Row>
                                <Col
                                    md={4}
                                    xs={4}
                                    sm={4}>
                                    <div className="preview-logo">
                                        {formNormalLogo && (
                                            <img
                                                src={formNormalLogo}
                                                alt="normal_logo"
                                            />
                                        )}
                                        {!formNormalLogo && (
                                            <img
                                                src="/ow.png"
                                                alt="normal_logo"
                                            />
                                        )}
                                    </div>
                                </Col>
                                <Col
                                    md={8}
                                    sm={8}
                                    xs={8}>
                                    <Form.Group
                                        controlId="formFile"
                                        className="mb-5 mt-4 ">
                                        <Form.Label className="mx-2">Selecione a logo da sua empresa (Formato completo)</Form.Label>

                                        <Form.Control
                                            type="file"
                                            name="file"
                                            onChange={handleNormalLogo}
                                        />

                                        <small className="mx-2">Formato sugerido : 100px X 50px</small>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    md={4}
                                    xs={4}
                                    sm={4}>
                                    <div className="preview-logo">
                                        {formSmallLogo && (
                                            <img
                                                src={formSmallLogo}
                                                alt="Small_logo"
                                            />
                                        )}
                                        {!formSmallLogo && (
                                            <img
                                                src="/ow.png"
                                                alt="Small_logo"
                                            />
                                        )}
                                    </div>
                                </Col>
                                <Col
                                    md={8}
                                    sm={8}
                                    xs={8}>
                                    <Form.Group
                                        controlId="formFile"
                                        className="mb-5 mt-4 ">
                                        <Form.Label className="mx-2">Selecione a logo da sua empresa (Formato reduzido)</Form.Label>

                                        <Form.Control
                                            type="file"
                                            name="file"
                                            onChange={handleSmallLogo}
                                        />

                                        <small className="mx-2">Formato sugerido : 32px X 32px</small>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Badge bg="warn">
                                    <svg
                                        className="w-6 h-6 mx-2 mr-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height={25}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                                        />
                                    </svg>
                                    Suas alterações irão impactar o visual da aplicação, tome cuidado com as mudanças de cor.
                                </Badge>
                                <Col
                                    md={3}
                                    xs={3}
                                    sm={3}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Cor primária</Form.Label>
                                        <Form.Control
                                            value={formColors.primary}
                                            type="color"
                                            name="primary"
                                            onChange={handleChangeColors}
                                            className="color-selector"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={3}
                                    xs={3}
                                    sm={3}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Cor secundária</Form.Label>
                                        <Form.Control
                                            value={formColors.secondary}
                                            type="color"
                                            name="secondary"
                                            onChange={handleChangeColors}
                                            className="color-selector"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={3}
                                    xs={3}
                                    sm={3}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Cor do texto</Form.Label>
                                        <Form.Control
                                            value={formColors.textColor}
                                            type="color"
                                            name="textColor"
                                            onChange={handleChangeColors}
                                            className="color-selector"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <ButtonOw
                                type={'submit'}
                                variant="primary"
                                loading={loadingButton}>
                                Salvar Configurações
                            </ButtonOw>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    function parametersTab() {
        return (
            <>
                <Row className="mt-4">
                    <Col
                        md={10}
                        sm={12}
                        xs={12}>
                        <Form onSubmit={updateCompanyData}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                            name='permitAnnonymousDeclarations'
                                            onChange={handleChangeParameters}
                                            label="Permitir que usuários enviem declarações anônimas"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    md={6}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Período padrão de emissão de nota</Form.Label>
                                        <Form.Control
                                            name="defaultTimeToSendTaxInvoice"
                                            as="select"
                                            onChange={handleChangeParameters}>
                                            <option value="every_day">Todo dia:</option>
                                            <option value="every_week_day">Toda semana na(o):</option>
                                            <option value="every_last_day_on_month">Todo ultimo dia do mês</option>
                                            <option value="every_first_day_on_month">Todo primeiro dia do mês</option>
                                            <option value="every_days">A cada (dias):</option>
                                            <option value="in_date">Exatamente no dia: </option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={6}
                                    xs={12}
                                    sm={12}>
                                    <Form.Group
                                        controlId="validationCustom01"
                                        className="mt-4 mb-4">
                                        <Form.Label className="mx-2">Emissão na data estabelecida</Form.Label>
                                        {optionsForPeriod()}
                                    </Form.Group>
                                </Col>

                                <Col
                                    md={12}
                                    xs={12}
                                    sm={12}
                                    className="mb-5">
                                    <Badge bg="warn">
                                        <svg
                                            className="w-6 h-6 mx-2 mr-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            height={25}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                                            />
                                        </svg>
                                        {textForPeriod[formParameters.defaultTimeToSendTaxInvoice]}
                                    </Badge>
                                </Col>
                            </Row>

                            <ButtonOw
                                type={'submit'}
                                className="mt-10"
                                variant="primary"
                                loading={loadingButton}>
                                Salvar Configurações
                            </ButtonOw>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    function optionsForPeriod() {
        if (formParameters.defaultTimeToSendTaxInvoice === 'every_week_day') {
            return (
                <Form.Control
                    as="select"
                    name="emitIn"
                    onChange={handleChangeParameters}>
                    <option value="monday">Segunda</option>
                    <option value="tuesday">Terça</option>
                    <option value="wednesday">Quarta</option>
                    <option value="thursday">Quinta</option>
                    <option value="friday">Sexta</option>
                    <option value="saturday">Sábado</option>
                    <option value="sunday">Domingo</option>
                </Form.Control>
            );
        } else if (['every_days', 'every_day'].includes(formParameters.defaultTimeToSendTaxInvoice)) {
            return (
                <Form.Control
                    type="text" pattern="\d*" maxlength="2"
                    name="emitIn"
                    onChange={handleChangeParameters}
                    className="input"
                />
            );
        } else if (formParameters.defaultTimeToSendTaxInvoice === 'in_date') {
            return (
                <Form.Control
                    type="date"
                    name="emitIn"
                    onChange={handleChangeParameters}
                    className="input"
                />
            );
        } else {
            return (
                <Form.Control
                    type="text"
                    className="input"
                    disabled
                    value="Nenhum parâmetro a ser especificado"
                />
            );
        }
    }

    return (
        <div className="dashboard mt-5">
            {loading ? (
                <h2>Loading..</h2>
            ) : (
                <Row>
                    <Col md={10}>
                        <h2 className="mt-3">Configurações</h2>
                        <p>Gerencie dados de sua empresa e apresentação dela para os colaboradores.</p>
                    </Col>

                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="data"
                            id="uncontrolled-tab-example"
                            className="mb-3">
                            <Tab
                                eventKey="data"
                                title="Dados">
                                {dataTab()}
                            </Tab>
                            <Tab
                                eventKey="parameters"
                                title="Parâmetros">
                                {parametersTab()}
                            </Tab>
                            <Tab
                                eventKey="colors"
                                title="Cores">
                                {colorsTab()}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            )}
        </div>
    );
}
