import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Card from '@components/Card';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import useService from '@hooks/useService';
import ButtonOw from '@components/ButtonOw';
import Image from 'react-bootstrap/Image';

import { Modal } from 'react-bootstrap';
import NotFound from '@components/NotFound';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Loading from '@components/Loading';
import { findMonth, money, formatDate } from '../../../../helpers';
export default function NFesEmployee() {
    const nfeService = useService('taxInvoices');
    const userService = useService('users');
    const documentsService = useService('documents');
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user_ow')))
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0,
    });
    const [form, setForm] = useState({
        search: '',
    });
    const [value, setValue] = useState(0);
    const [loadingView, setLoadingView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [viewSheet, setViewSheet] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [show, setShow] = useState(false);
    const [noBank, setNoBank] = useState(false)
    const handleCloseUpload = () => setShowUpload(false);
    const handleShowUpload = () => setShowUpload(true);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };
    useEffect(() => {
        if (form.search.length > 0) {
            search();
        } else {
            fetchNfes();
        }
    }, [page]);

    useEffect(() => {
        getBankData();
    }, [])
    async function getBankData() {
        try {
            const { data } = await userService.get(userData.id);
            const { bankData } = data;

            if (!bankData) {
                setNoBank(true)
            }
        } catch (err) {
            console.log(err)
            setNoBank(true)
        }
    }
    async function fetchNfes() {
        setLoading(true);
        try {
            // const userData = JSON.parse(localStorage.getItem('user_ow'));
            const populates = 'companyId:name,id';
            const result = await nfeService.list(`page=${page}&populate=${populates}&userId=${userData.id}`);
            setPaginationData(result.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err);
        }
    }
    async function search() {
        if (form.search.length > 0) {
            setLoading(true);
            try {
                // const userData = JSON.parse(localStorage.getItem('user_ow'));
                const populates = 'companyId:name,id';
                const result = await nfeService.list('page=' + page + '&filename=' + form.search + '&populate=' + populates + 'userId=' + userData.id);
                setPaginationData(result.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err);
            }
        } else {
            fetchNfes();
        }
    }
    function handleChange(e) {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    function showReference() {
        // const userData = JSON.parse(localStorage.getItem('user_ow'));
        let reference = userData.companyData.next_tax_invoice_send_in;
        if (new Date().getTime() > new Date(userData.companyData.next_tax_invoice_send_in)) {
            reference = userData.companyData.last_tax_invoice_sended_in;
        }

        return (
            <Form.Group
                as={Col}
                md="12"
                controlId=""
                className="mb-3">
                <Form.Label className="mx-2">Data de referência</Form.Label>
                <p>A nota que esta enviando é referente a data de {reference}</p>
            </Form.Group>
        );
    }

    async function downloadFile(filename) {
        try {
           
            // const file = await nfeService.getDocument(filename);
            window.open('http://api.onework.com.br/' + filename)

            // const href = URL.createObjectURL('http://api.onework.com.br/' + filename);

            // // create "a" HTML element with href to file & click
            // const link = document.createElement('a');
            // link.href = href;
            // link.setAttribute('download', filename); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(href);
        } catch (err) {
            console.log(err);
        }
    }

    async function createNfe(event) {
        setLoadingCreate(true);
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData(event.currentTarget);
        formData.append('value', value);
        try {
            const res = await nfeService.uploadTaxInvoice(formData);
            if (res) {
                toast.success('Nfe enviada com sucesso');
            } else {
                toast.error('Parece que tivemos um erro para receber sua NFe. Tente novamente mais tarde.');
            }
            setLoadingCreate(false);
            handleClose();
            handleCloseUpload();
        } catch (err) {
            toast.error(err);
        }
    }
    function changeView() {
        setLoadingView(true);
        const timer = setTimeout(() => {
            setViewSheet(!viewSheet);
            setLoadingView(false);
        }, 1500);
        return () => clearTimeout(timer);
    }
    function NothingFound() {
        return (
            <div>
                <div className="notFound">
                    <h4>Não encontramos nenhuma NFe, deseja criar uma?</h4>
                    <ButtonOw
                        type={'button'}
                        variant={'secondary'}
                        disabled={noBank}
                        onClick={() => handleShow()}>
                        Nova nota
                    </ButtonOw>
                    <br />
                    <img
                        alt="No Data"
                        src="/no-nf.jpeg"
                        className="blend-img"
                        width={400}
                    />
                </div>
            </div>
        );
    }

    async function download(filename) {
        try {
            await documentsService.getDocument(filename);
        } catch (err) {
            toast.error(err);
        }
    }

    function ListAndPaginate() {
        return (
            <Table
                hover
                className="rounded-table">
                <thead>
                    <tr>
                        <th>Filename</th>
                        <th>Valor</th>
                        <th>Mês de referência</th>
                        <th>Data de upload</th>
                        <th style={{ width: '250px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {paginationData.list.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{item.filename}</td>
                                <td>{money(item.value)}</td>
                                <td>{findMonth(item.month)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td>
                                    <Col className="action-buttons">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={tooltip('Download')}>
                                            <a
                                                href="#"
                                                onClick={() => downloadFile(item.document_uri)}>
                                                <svg
                                                    height={20}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                                    />
                                                </svg>
                                            </a>
                                        </OverlayTrigger>
                                    </Col>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }
    function ListColumns() {
        return (
            <>
                {paginationData.list.map((item, i) => {
                    return (
                        <Col
                            md={2}
                            lg={2}
                            xl={2}
                            className="mb-4">
                            <Card
                                actions={[
                                    {
                                        label: 'Baixar',
                                        action: () => downloadFile(item.document_uri),
                                    },
                                ]}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                                    />
                                </svg>
                                <Badge bg={'success-ow'}>
                                    {findMonth(item.month)}/{item.year}
                                </Badge>
                                <div className="card-bottom">
                                    <Row>
                                        <Col>
                                            <b>{money(item.value)}</b>
                                            <br />
                                            <small>Enviado: {formatDate(item.updatedAt)}</small>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    );
                })}
            </>
        );
    }

    return (
        <div className="dashboard mt-5">
            {loading ? (
                <div className="d-flex justify-content-center align-itens-center">
                    <Loading variant="secondary" />
                </div>
            ) : (
                <>
                    {noBank && <Badge bg={'warn'} className='mb-5 '>
                        <svg className="w-6 h-6 mx-2 mr-2" xmlns="http://www.w3.org/2000/svg" height={25} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                        </svg>
                        Parece que você ainda não adicionou seus dados bancários. Adicione para poder emitir uma Nota fiscal. <Link to='/e/bank' className='text-link'>Clique aqui para adicionar.</Link>
                    </Badge>}
                    <Row>
                        <Col md={10}>
                            <h2>Notas Fiscais</h2>
                            <p>Visualize a organize melhor suas NFes.</p>
                        </Col>
                        <Col
                            md={2}
                            className="">
                            <ButtonOw
                                variant="primary"
                                disabled={noBank}
                                onClick={handleShow}
                                type={'button'}>
                                <svg
                                    width={18}
                                    style={{ marginRight: 10 }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                                Nova nota
                            </ButtonOw>
                        </Col>
                        <Modal
                            show={showUpload}
                            onHide={handleCloseUpload}>
                            {userData.companyData.next_tax_invoice_send_in ? <>
                                <Form onSubmit={createNfe}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Faça o upload de sua NFe</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <Form.Group
                                            as={Col}
                                            md="12"
                                            controlId=""
                                            className="mb-3">
                                            <Form.Label className="mx-2">Valor</Form.Label>
                                            <CurrencyInput
                                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                defaultValue={1000}
                                                decimalsLimit={2}
                                                placeholder="Ex: R$5.000"
                                                onValueChange={(value) => setValue(value)}
                                                className="input form-control"
                                            />
                                        </Form.Group>

                                        {showReference()}

                                        {/* <Form.Group as={Col} md="12" controlId="validationCustom01" className="mb-3">
                                    <Form.Label className="mx-2">Mês de referência</Form.Label>
                                    <Form.Select name='month'>
                                        <option>Selecione o mês de referência</option>
                                        <option value="1">Janeiro</option>
                                        <option value="2">Fevereiro</option>
                                        <option value="3">Março</option>
                                        <option value="4">Abril</option>
                                        <option value="5">Maio</option>
                                        <option value="6">Junho</option>
                                        <option value="7">Julho</option>
                                        <option value="8">Agosto</option>
                                        <option value="9">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </Form.Select>
                                </Form.Group> */}

                                        <Form.Group
                                            controlId="formFile"
                                            className="mb-3 ">
                                            <Form.Label
                                                className="mx-2"
                                                for="file">
                                                Selecione seu arquivo
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="file"
                                                accept=".pdf"
                                            />
                                        </Form.Group>
                                        <Badge bg="warn">
                                            <svg
                                                className="mx-2 mr-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                height={25}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                                                />
                                            </svg>
                                            <small>São aceitos apenas arquivos em PDF</small>
                                        </Badge>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ButtonOw
                                            variant="primary"
                                            type={'submit'}
                                            loading={loadingCreate}>
                                            Enviar
                                        </ButtonOw>
                                    </Modal.Footer>
                                </Form></> : <> <Modal.Header closeButton>
                                    <Modal.Title>Faça o upload de sua NFe</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><h6>A empresa não configurou os parâmetros para recebimento de nota.</h6></Modal.Body>
                                <Modal.Footer>
                                    <ButtonOw
                                        variant="primary"
                                        type={'button'}
                                        onClick={() => handleCloseUpload()}
                                    >
                                        Fechar
                                    </ButtonOw>
                                </Modal.Footer></>}
                        </Modal>
                        <Modal
                            show={show}
                            onHide={handleClose}>
                            <Form onSubmit={createNfe}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Selecione como enviar sua NFe</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-4">
                                    <Row>
                                        <Col>
                                            <div
                                                className="nfe-options"
                                                onClick={handleShowUpload}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                                                    />
                                                </svg>

                                                <h3>Fazer o upload de nota fiscal</h3>
                                            </div>
                                        </Col>
                                        {/* <Col>

                                        <div className='nfe-options' onClick={() => window.open('https://ficatranquilo.com.br/trocar-de-contador')}>
                                            <Badge bg={'success-ow'}>Novo</Badge>
                                            <img src="/ficatranquilo.svg" className="mx-2" alt='FicaTranquilo' width={120} />

                                            <h3>
                                                Emitir nova nota fiscal
                                            </h3>


                                        </div>
                                    </Col> */}
                                    </Row>
                                </Modal.Body>
                            </Form>
                        </Modal>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Pesquisar pelo nome"
                                    name="search"
                                    className="input"
                                    onChange={handleChange}
                                    defaultValue={form.search}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <ButtonOw
                                type={'button'}
                                variant={'secondary'}
                                onClick={search}>
                                Filtrar
                            </ButtonOw>
                        </Col>
                        <Col md={2}>
                            {!viewSheet ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={tooltip('Visualizar como Tabela')}>
                                    <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg
                                                width={18}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="size-6">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={tooltip('Visualizar como Card')}>
                                    <div>
                                        <ButtonOw
                                            type={'button'}
                                            variant={'white'}
                                            onClick={changeView}>
                                            <svg 
                                            width={18}
                                            xmlns="http://www.w3.org/2000/svg" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke-width="1.5" 
                                            stroke="currentColor" 
                                            class="size-6">
                                                <path 
                                                stroke-linecap="round" 
                                                stroke-linejoin="round" 
                                                d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
                                            </svg>

                                        </ButtonOw>
                                    </div>
                                </OverlayTrigger>
                            )}
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        {loadingView ? (
                            <div className="d-flex justify-content-center align-itens-center mb-5 mt-5">
                                <Loading variant="secondary" />
                            </div>
                        ) : paginationData.totalRegisters === 0 ? (
                            NothingFound()
                        ) : viewSheet ? (
                            ListAndPaginate()
                        ) : (
                            ListColumns()
                        )}
                    </Row>
                </>
            )
            }
        </div >
    );
}
