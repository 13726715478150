import BaseService from "./Base.Service";

class ProjectsService extends BaseService {
	constructor() {
		const model = {
			name: { type: String, required: true },
			users: { type: Array, required: true }
		};

		super("projects", model);
	}

    async getHours() {
        try {
            const { data } = await this.conn.axios.get(`/projects/get-list-dash-emp`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
    async report(id) {
        try {
            const { data } = await this.conn.axios.get(`/projects/${id}/report`);
            return data;
        } catch (err) {
            return this.sendError("Erros foram encontrados ao recuperar dados", false, err);
        }
    }
}

export default ProjectsService;
