import { useState, useEffect } from 'react';
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
// const documentsService = useService('documents');

export function findMonth(month) {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    return meses[month - 1]
}
export function money(value) {
    if (value) {
        var valor = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        return valor
    } else {
        return '-'
    }

}
export function formatDate(date) {
    if (!date) return
    let data = new Date(date);
    let dataFormatada = ((data.getDate())) + "/" + ((data.getMonth() + 1)) + "/" + data.getFullYear();
    return dataFormatada
}

export const maskDocument = (v) => {
    if (!v) return
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
}

// Returns a single rgb color interpolation between given rgb color
// based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
};
// My function to interpolate between two colors completely, returning an array
export const interpolateColors = (color1, color2, steps) => {
    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for (var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}

export const hexToRgb = (hex, obj = false) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (obj) {
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    } else {
        return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null
    }
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const downloadExcelAsArrayBuffer = async (excelUrl) => {
    try {
      // Fetch the Excel file
      const response = await fetch(excelUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to download Excel file. Status: ${response.status}`);
      }
      // Convert the response to ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();
      return arrayBuffer;
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      throw error;
    }
  };

  export const convertExcelToJson = async (excelUrl) => {

    try {
      // Download the Excel file as ArrayBuffer
    //   const arrayBuffer = await downloadExcelAsArrayBuffer(excelUrl);
  
      // Convert the Excel buffer to a workbook
      const workbook = XLSX.read(excelUrl, { type: "array" });
      
      // Get the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      
      // Convert the sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
      // Map the array to an array of objects
      const resultArray = jsonData.map((row) => {
        const obj = {};
        for (let i = 0; i < jsonData[0].length; i++) {
          obj[jsonData[0][i]] = row[i];
        }
        return obj;
      });
  
      return resultArray.slice(1);
    } catch (error) {
      console.error("Error converting Excel to JSON:", error);
      throw error;
    }
  };